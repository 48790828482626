<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                    <label>Show
                                    <select v-model='perPage' @change="perPagefunc()" class=" form-control form-control-sm d-inline" style="width: 75px;">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    entries</label>
                                    <div class="mb-3">
                                        <div class="form-row">
                                            <span>User</span>
                                            <div class="col-2">
                                              <input list="browsers" class="form-control" v-model = 'filterColumnUser' @change="GetId()">

                                              <datalist id="browsers">
                                                  <option v-for = "user in users" v-bind:key="user.email">{{user.email}}</option>
                                              </datalist>
                                            </div>
                                            <span>Status</span>
                                            <div class="col-2">
                                                <select type="text" class="form-control" v-model="filterColumnStatus">
                                                    <option></option>
                                                    <option>initial</option>
                                                    <option>busy</option>
                                                    <option>success</option>
                                                    <option>fail</option>
                                                </select>
                                            </div>
                                            <span>Order UUID</span>
                                            <div class="col-2">
                                                <input type="text" class="form-control" v-model = 'filterColumnOrderUUID'>
                                            </div>
                                            <span>Import UUID</span>
                                            <div class="col-2">
                                                <input type="text" class="form-control" v-model = 'filterColumnImportUUID'>
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn btn-info" @click="filterColumnFunc()">Search <i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                        <thead>
                                           <tr>

                                               <th @click = "boolsort = !boolsort,SortColumns('file')" style="cursor: pointer;">File Name <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('order_uuid')" style="cursor: pointer;">Order UUID <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('uuid')" style="cursor: pointer;">Import UUID <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('user_email')" style="cursor: pointer;">User <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('status')" style="cursor: pointer;">Status <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('result')" style="cursor: pointer;">Result <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th @click = "boolsort = !boolsort,SortColumns('created_on')" style="cursor: pointer;">Create On <i class="fa fa-sort" aria-hidden="true"></i></th>
                                               <th>Line Count</th>
                                               <th>Imported</th>
                                           </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in file_list" :key="item.url">
                                                <td><button type="button" class="btn btn-link" @click="downloadUrl(item.url)">{{ item.file }}</button></td>
                                                <td>{{ item.order_uuid }}</td>
                                                <td>{{ item.uuid }}</td>
                                                <td>{{ item.user_email }}</td>
                                                <td>{{ item.status }}</td>
                                                <td>{{ item.result }}</td>
                                                <td>{{ item.created_on? item.created_on.split('T').join(' ').split('.').shift(): '' }}</td>
                                                <td>{{ item.line_count }}</td>
                                                <td>{{ item.result_imported }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                                        Showing {{ count1 }} to {{ count }} of {{ num }} entries
                                        </div>
                                    </div>
                                    <nav class="col-sm-12 col-md-7">
                                      <ul class="pagination justify-content-end" @click="paginationTables()">
                                        <v-pagination :total="total" v-model="currentPage"></v-pagination>
                                      </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import VueCookies from 'vue-cookies'
    import VuePagination from 'vue-bs-pagination'

    export default {
        data:function () {
            return{
                admintoken:VueCookies.get('admintoken'),
                file_list:[],
                users:[],
                file:'',
                email:'',
                app:'',
                isActive:true,
                type:this.tabType,
                apps:[],
                user_id:'',
                user_id1:'',
                order_duration:'',
                imported:'',
                date:'',
                time:'',
                FirstInput:'',
                // from_number:'',
                // to_number:''

                pagesCount:'',
                total: 1,
                currentPage:1,
                perPage: 10,
                eachSide: 1,
                num:0,
                count:10,
                count1:1,
                boolsort:false,
                sortItem:'created_on',

                filterColumnStatus:'',
                filterColumnUser:'',
                filterColumnImportUUID:'',
                filterColumnOrderUUID:'',

                UserActive:true
            }
        },
        components:{
            'v-pagination':VuePagination
        },
        methods:{
            changeActive:function(active){
                this.isActive = active
            },
            GetUsers:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`,{
                    params:{
                        per_page:1000
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    }
                }).then((res)=>{
                    this.users = res.data.payload.items
                })
            },
            GetUsersId:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`,{
                    params:{
                        email:this.email,
                        per_page:1000
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    }
                }).then((res)=>{
                    this.user_id = res.data.payload.items[0].user_id
                })
            },
            UploadFile:function(){
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('order_duration',this.order_duration);
                formData.append('user_id',this.user_id)
                // console.log(this.file);
                // console.log(this.order_duration);
                // formData.append('from_number',this.from_number)
                // formData.append('to_number',this.to_number)
                axios.post(`${process.env.VUE_APP_API_BASE_URL}/file/import`,
                    formData,
                    {
                        headers: {
                            'X-Auth-Token': this.admintoken,
                            'Content-Type': 'multipart/form-data'
                        }
                }).then(()=>{
                    //console.log(res)
                    this.GetFiles()
                })
            },
            handleFileUpload(){
               this.file = this.$refs.file.files[0];
            },

            GetFiles:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/file/list`,{
                    params: {
                        user_id:this.user_id1,
                        status:this.filterColumnStatus,
                        uuid:this.filterColumnImportUUID,
                        order_uuid:this.filterColumnOrderUUID,
                        order_by:this.sortItem,
                        order_dir: this.boolsort ? 'asc' : 'desc',
                        per_page:this.perPage,
                        page:this.currentPage-1
                    },
                    headers: {
                        'X-Auth-Token': this.admintoken,
                    }
                }).then((res)=>{

                    //console.log(res);

                    this.file_list = res.data.payload.items
                    this.num = res.data.payload.total
                    this.total = Math.ceil(this.num/Number(this.perPage))
                })
            },
            perPagefunc:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/file/list`,{
                    params: {
                        user_id:this.user_id1,
                        status:this.filterColumnStatus,
                        uuid:this.filterColumnImportUUID,
                        order_uuid:this.filterColumnOrderUUID,
                        order_by:this.sortItem,
                        order_dir:this.boolsort ? 'asc' : 'desc',
                        per_page:this.perPage,
                        page:this.currentPage-1
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    },
                }).then((res)=>{
                    this.file_list = res.data.payload.items
                    this.num = res.data.payload.total
                    this.total = Math.ceil(this.num/Number(this.perPage))
                    this.count = Number(this.currentPage*this.perPage)
                    this.count1 = Number(this.count - this.perPage + 1)
                })
            },
            paginationTables:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/file/list`,{
                    params: {
                        user_id:this.user_id1,
                        status:this.filterColumnStatus,
                        uuid:this.filterColumnImportUUID,
                        order_uuid:this.filterColumnOrderUUID,
                        order_by:this.sortItem,
                        order_dir:this.boolsort ? 'asc' : 'desc',
                        per_page:this.perPage,
                        page:this.currentPage-1
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    },
                }).then((res)=>{
                    this.file_list = res.data.payload.items
                    this.count = Number(this.currentPage*this.perPage)
                    this.count1 = Number(this.count - this.perPage + 1)
                })
            },
            SortColumns:function(item){
                this.sortItem = item;
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/file/list`,{
                    params:{
                        user_id:this.user_id1,
                        status:this.filterColumnStatus,
                        uuid:this.filterColumnImportUUID,
                        order_uuid:this.filterColumnOrderUUID,
                        order_by:item,
                        order_dir:this.boolsort ? 'asc' : 'desc',
                        per_page:this.perPage,
                        page:this.currentPage-1
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    },
                }).then((res)=>{
                    this.file_list = res.data.payload.items
                })
            },
            filterColumnFunc:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/file/list`,{
                    params:{
                        user_id:this.user_id1,
                        status:this.filterColumnStatus,
                        uuid:this.filterColumnImportUUID,
                        order_uuid:this.filterColumnOrderUUID,
                        per_page:this.perPage,
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    },
                }).then((res)=>{
                    this.file_list = res.data.payload.items
                    this.num = res.data.payload.total
                    this.total = Math.ceil(this.num/Number(this.perPage))
                })
            },
            Decimal:function(){
                if (this.FirstInput !== '') {
                    var regex = /[0-9]/;
                    if (!this.FirstInput[this.FirstInput.length - 1].match(regex)) {
                        this.FirstInput = this.FirstInput.slice(0, -1)
                    }
                    else {
                        return
                    }
                }

            },
            GetId:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`,{
                    params:{
                        email:this.filterColumnUser,
                        per_page:1000
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    }
                }).then((res)=>{
                    if (res.data.payload.items.length == 1)
                        this.user_id1 = res.data.payload.items[0].user_id
                    else{
                        this.user_id1 = ''
                    }
                })
            },
            GetApp:function(){
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/app/list`,{
                    params:{
                        per_page:1000
                    },
                    headers:{
                        'X-Auth-Token': this.admintoken,
                    },
                }).then((res)=>{
                    this.apps = res.data.payload.items
                })
            },
            downloadUrl:function(url){
                console.log('url-->',url)
                window.open(url, '_blank');
            }
        },
        created(){
            this.GetUsers()
            this.GetFiles()
            this.GetApp()
        }
    }

</script>
<style>

</style>
