<template>
  <div class="container wrap-container8040" style="background: transparent;">
    <h5 style="color: white; margin-bottom: 24px;" class="text-center">{{ $t('test-now-page.title') }}</h5>
    <div class="row no-gutters" style="margin: 0 0 24px 0;">
      <div
        class="col-xs-6"
        v-for="item in number_list"
        :key="item.number_uuid"
        style="
          background: #fff;
          text-align: center;
          padding: 8px 0;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          font-size: 14px;
        "
      >
        {{ item.number }}
      </div>
    </div>
    <hr />
    <h5 style="color: white; margin-bottom: 24px;" class="text-center">{{ $t('test-now-page.inbound-messages') }}</h5>
    <div class="table-responsive">
      <table class="table table-bordered" style="background: #fff; font-size: 14px;">
        <thead>
          <tr>
            <th scope="col">Message</th>
            <th scope="col">Time</th>
            <th scope="col">From</th>
            <th scope="col">To</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in sms_records" :key="item.uuid">
            <td>{{ item.msg }}</td>
            <td>{{ moment(item.created_on).format('LLLL') }}</td>
            <td>{{ item.from_id }}</td>
            <td>{{ item.to }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numbers from '../helprers/numbers.js'
import api from './api'

export default {
  name: 'SmsTable',
  data: () => ({
    sms_records: [],
    number_list: [],
    interval: ''
  }),
  mounted() {
    this.number_list = numbers
    this.setupSmsRecords()
    // this.setInterval()
  },
  // destroyed() {
  // clearInterval(this.interval)
  // },
  methods: {
    // setInterval() {
    //   this.interval = setInterval(() => {
    //     this.setupSmsRecords()
    //   }, 60000)
    // },
    async getRecords() {
      const records = this.number_list.map(async (item) => {
        const res = await api.RECEIVE_SMS(item.number_uuid)
        return res?.payload.items
      })
      return Promise.all(records)
    },
    async setupSmsRecords() {
      const array = []
      const records = await this.getRecords()
      if (!records.includes(undefined)) {
        await records.filter((item) => item.length).map((item) => array.push(...item))
        await array.sort(function (a, b) {
          return new Date(a.created_on).getTime() - new Date(b.created_on).getTime()
        })
        this.sms_records = await array.slice(-30).reverse()
      }
    }
  }
}
</script>
