<template>
  <div class="modal fade" id="ModalChangePassword" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-row">
            <div class="col-md-4">
              <h4>
                <b><label for="password">new password</label></b>
              </h4>
            </div>
            <div class="col-md-8">
              <input v-model="password" class="form-control" type="password" name="email" id="password" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" data-dismiss="modal" @click.prevent="onChangeHandler()">
            Change
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangePassword',
  data: () => ({
    password: ''
  }),
  watch: {
    password(val) {
      if (val) this.$emit('set-new-pass', val)
    }
  },
  methods: {
    onChangeHandler() {
      this.$emit('on-change-handler')
    }
  }
}
</script>

<style scoped></style>
