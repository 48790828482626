<template>
  <div class="container margin-top40" style="height: 80vh;">
    <form @submit.prevent="ResetPass">
      <div class="logo">
        <span class="db">
          <img src="assets/images/logo-icon.png" alt="logo" />
        </span>
        <h5 class="font-medium m-b-20">Recover Password</h5>
      </div>
      <div>
        <div class="col-12">
          <div class="form-group row">
            <div class="input-group-prepend">
              <span id="basic-addon1" class="input-group-text">
                <i class="ti-user"></i>
              </span>
            </div>
            <input
              type="text"
              placeholder="Email"
              name="user_name"
              required="required"
              class="form-control form-control-lg"
              v-model="email"
            />
          </div>
          <div class="form-group text-center">
            <div class="col-xs-12 p-b-20">
              <button type="submit" class="btn btn-block btn-lg btn-info" :disabled="!isSubmit">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  data() {
    return {
      email: '',
      name: '',
      token: VueCookies.get('usertoken'),
      sended: false
    }
  },
  computed: {
    isSubmit() {
      return this.email
    }
  },
  methods: {
    ResetPass() {
      if (this.sended) {
        alert('confirm to change password')
      } else {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/auth/reset_email`, {
            email: this.email
          })
          .then(() => {
            alert('the link to reset password has been sent to your email, please check')
            this.sended = true
          })
      }
    }
  }
}
</script>

<style scoped></style>
