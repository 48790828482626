<template>
    <div class="row">

        <div  class="modal fade" id="MailConf" tabindex="-1" role="dialog" aria-labelledby="MailConf" aria-hidden="true">
            <div class="modal-dialog" role="document" style="max-width: 855px">
                <div class="modal-content" style="width: 855px;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="MailConf1">Mail Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div v-for="object in list_object" :key="object.name">
                        <h3 class="mt-2 ml-3">{{object.name.charAt(0).toUpperCase() + object.name.slice(1)}}</h3>
                        <div class="form-row ml-2 pl-2 pr-2 mt-2">
                            <div class="col-md-4">
                                <h4><b><label>From Email</label></b></h4>
                            </div>
                            <div class="col-md-8">
                                <input v-model="object.email_from" required="required" class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-2 ml-2">
                            <div class="col-md-4">
                                <h4><b><label>Cc</label></b></h4>
                            </div>
                            <div class="col-md-8 ">
                                <input v-model="object.email_cc" required="required" class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-2 ml-2">
                            <div class="col-md-4">
                                <h4><b><label>Subject</label></b></h4>
                            </div>
                            <div class="col-md-8 ">
                                <input v-model="object.subject" required="required" class="form-control" type="text">
                            </div>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-3 ml-2">
                            <div class="col-md-4">
                                <h4><b><label>Content</label></b></h4>
                            </div>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-2 ">
                            <vue-editor :ref="'editor' + object.name" v-model="object.content_html"></vue-editor>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-3 ml-2">
                            <div class="col-md-4">
                                <h4><b><label>Tags</label></b></h4>
                            </div>
                        </div>
                        <div class="form-row pl-2 pr-2 mt-2 mb-2 ml-2">
                            <button class="btn btn-info ml-2" v-for="tag in tags" :key="tag" @click ="object.content_html += `{{${tag}}}`">{{tag}}</button>

                            <button class="btn btn-info ml-2" @click ="object.content_html += '{File}'">{File}</button>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="saveItem(object)">Save</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor'
    import VueCookies from 'vue-cookies'
    import axios from 'axios'
    export default {
        name: "AdminMail",
        data: function () {
            return {
                token: VueCookies.get('admintoken'),
                list_object: [],
                tags:[
                    'user.user_id',
                    'user.first_name',
                    'user.last_name',
                    'user.email',
                    'user.is_admin',
                    'user.last_login',
                    'user.is_active',
                    'user.alert_on_order_expiration',
                    'user.alert_on_delivery_error',
                    'user.alert_on_payment',
                    'user.created_on',
                    'user.user_name',
                    'user.account.credit',
                    'user.account.debit',
                    'user.account.balance'
                ]
            }
        },
        components: {
            VueEditor,
        },
        methods: {
            saveItem: function (obj){

                    for (let i in obj){
                        if (obj[i] == null || obj[i]==''){
                            alert(`Warning, ${i} field may not be null`)
                            return
                        }
                    }

                axios.patch(`${process.env.VUE_APP_API_BASE_URL}/mail_template/${obj.name}`,obj, {
                    headers:{
                        'X-Auth-Token': this.token,
                    }
                }).then(() => {
                    this.fetchData()
                })


            },
            fetchData: function () {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/mail_template/list`, {
                    headers:{
                        'X-Auth-Token': this.token,
                    }
                }).then((res) => {
                    this.list_object = res.data.payload.items
                })
            }

        },
        created(){

            this.fetchData();
        }
    }

</script>

<style scoped>

</style>
