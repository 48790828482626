<template>
    <ul class="nav navbar-nav navbar-right">
        <li><a href="https://doc.globalsms.io" target="_blank" rel="noreferrer noopener">API</a></li>
        <li><router-link to = "/receive-sms">Receive now</router-link></li>
        <!-- <li><router-link to = "/test">{{ $t('menu.test-now') }}</router-link></li> -->
        <li><router-link to = "/price">{{ $t('menu.pricing') }}</router-link></li>
        <li><a href="https://t.me/kayakian" target="_blank" rel="noreferrer noopener">{{ $t('menu.support') }}</a></li>
        <li><router-link to = "/register">{{ $t('menu.register') }}</router-link></li>
        <li><router-link to = "/login">{{ $t('menu.login') }}</router-link></li>
        <li><CountryNumberSwitcherVue></CountryNumberSwitcherVue></li>
        <li><LocaleSwitcher></LocaleSwitcher></li>
    </ul>
</template>

<script>
    import LocaleSwitcher from "./LocaleSwitcher";
    import CountryNumberSwitcherVue from "./CountryNumberSwitcher";
    export default {
        components: {
            CountryNumberSwitcherVue,
            LocaleSwitcher
        },
    }
</script>
