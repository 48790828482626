import Vue from 'vue'
import Router from 'vue-router'
import Home from './Home.vue'
import Test from './common/SmsTable.vue'
import Features from './Features'
import Price from './Price.vue'
import Register from './Register.vue'
import Login from './Login.vue'
import Forget_Pass from './Forget_Pass.vue'
import Reset_pass from './Reset_Pass.vue'
import ReceiveSMS from './common/ReceiveSMS.vue'
import SuccessReg from './common/SuccessReg.vue'
import FailureReg from './common/FailureReg.vue'
import RefundPolicy from './RefundPolicy.vue'
import PrivacyPolicy from './PrivacyPolicy.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/receive-sms',
      name: 'receive-sms',
      component: ReceiveSMS
    },
    {
      path: '/test',
      name: 'test',
      component: Test
    },
    {
      path: '/features',
      name: 'features',
      component: Features
    },
    {
      path: '/price',
      name: 'price',
      component: Price
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/success',
      name: 'register-success',
      component: SuccessReg
    },
    {
      path: '/failure',
      name: 'register-failure',
      component: FailureReg
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/forget_pass',
      name: 'forget_pass',
      component: Forget_Pass
    },
    {
      path: '/reset_pass',
      name: 'reset_pass',
      component: Reset_pass
    },
    {
      path: '/refund-policy',
      name: 'refund-policy',
      component: RefundPolicy
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy
    }
  ]
})
