<template>
  <div id="pricing" class="wrap-container8040 bg-grey">
    <div class="container">
      <div class="post-heading-center">
        <h2>Pricing Table</h2>
      </div>
      <UserPricing />
    </div>
  </div>
</template>

<script>
import UserPricing from './UserSide/UserPricing.vue'
export default {
  components: {
    UserPricing
  }
}
</script>
