<template>
  <div class="container padding-top40" style="height: 80vh;">
    <form @submit.prevent="LogIn">
      <div class="logo margin-bottom20">
        <span class="db">
          <img src="assets/images/logo-icon.png" alt="logo" />
        </span>
        <h5 class="font-medium m-b-20">{{ $t('login-page.sign-in') }}</h5>
      </div>
      <div class="alert alert-danger margin-bottom20 text-center" role="alert" v-if="isAlert">
        {{ error }}
      </div>
      <div>
        <div class="col-12">
          <div class="form-group row">
            <div class="input-group-prepend">
              <span id="basic-addon1" class="input-group-text">
                <i class="ti-user" />
              </span>
            </div>
            <input
              type="text"
              :placeholder="$t('login-page.email')"
              name="user_name"
              required="required"
              class="form-control form-control-lg"
              v-model="login"
            />
          </div>
          <div class="form-group row">
            <div class="input-group-prepend">
              <span id="basic-addon" class="input-group-text">
                <i class="ti-user" />
              </span>
            </div>
            <input
              type="password"
              :placeholder="$t('login-page.password')"
              name="password"
              required="required"
              class="form-control form-control-lg"
              v-model="password"
            />
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1" v-model="checked" />
                <label class="custom-control-label" for="customCheck1" style="margin-left: 10px; color: #1c458b;">
                  {{ $t('login-page.remember-me') }}
                </label>
                <router-link to="/forget_pass" class="text-info m-l-5" style="margin-left: 60px;">
                  <b style="color: #5a2102;">{{ $t('login-page.forgot-password') }}</b>
                </router-link>
              </div>
            </div>
          </div>
          <div class="form-group text-center">
            <button
              type="submit"
              class="btn btn-block btn-lg btn-info margin-bottom10"
              @click.prevent="LogIn"
              :disabled="!isAllow"
            >
              {{ $t('login-page.log-in') }}
            </button>
            <div class="col-sm-12 text-center">
              <span style="color: #1c458b;">{{ $t('login-page.dont-have-account') }}</span>
              <a href="/register" class="text-info">
                <b style="color: #5a2102; padding-left: 12px;">{{ $t('login-page.sign-up') }}</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import router_user from './router_user.js'
import router_admin from './router_admin.js'

export default {
  data: function () {
    return {
      admintoken: '',
      token: '',
      login: '',
      password: '',
      error: '',
      checked: false,
      isAlert: false
    }
  },
  computed: {
    isAllow() {
      return this.login && this.password
    }
  },
  methods: {
    LogIn: function () {
      this.isAlert = false
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/auth`, {
          user_name: this.login,
          password: this.password
        })
        .then((res) => {
          console.log(res.data)
          if (res.data.success === true) {
            if (res.data.payload.user_type === 'user') {
              this.token = res.data.payload.token
              VueCookies.set('usertoken', this.token)
              VueCookies.set('useremail', this.login)

              router_user.push({ name: 'usermenue' })
              location.reload()
            }
            if (res.data.payload.user_type === 'admin') {
              this.admintoken = res.data.payload.token
              VueCookies.set('admintoken', this.admintoken)
              VueCookies.set('adminemail', this.login)

              router_admin.push({ name: 'adminmenue' })
              location.reload()
            }
            this.login = this.password = this.error = ''
          }
        })
        .catch((error) => {
          if (error && error.response.data) {
            this.displayAlert(error.response.data.error.code)
          } else {
            this.error = 'Login error'
            this.isAlert = true
          }
        })
    },
    displayAlert(code) {
      switch (code) {
        case 100:
          this.error = 'Login or Password are incorrect'
          break
        case 10000:
          this.error = 'Your account is disabled'
          break
        default:
          this.error = 'Login error'
      }
      this.isAlert = true
    }
  }
}
</script>
