<template>
  <div id="app">
    <nav id="navigation_affix">
      <div class="container">
        <div class="navbar-brand">
          <a href="/"><img src="./assets/images/logo_v4.png" alt="Logo" /></a>
        </div>
        <ul class="nav navbar-nav">
          <NavLinks></NavLinks>
        </ul>
      </div>
    </nav>

    <header id="header">
      <nav id="navigation" class="navbar">
        <div class="container">
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#menu-collapse-1">
              <span class="sr-only">Menu</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div class="navbar-brand">
              <router-link to="/">
                <img src="./assets/images/logo_v4.png" alt="Logo" />
              </router-link>
            </div>
          </div>
          <div class="collapse navbar-collapse" id="menu-collapse-1">
            <NavLinks />
          </div>
        </div>
      </nav>
      <router-view />
    </header>

    <div v-if="!currentPageIsPolicy" id="features4" class="wrap-container8040 bg-grey">
      <div class="">
        <ul class="nav navbar-nav">
          <!--<ul class="">-->
        </ul>
      </div>

      <!-- .container -->
      <div class="container">
        <div class="post-heading-center">
          <h2>{{ $t('features.title') }}</h2>
        </div>

        <!-- .row-custom -->
        <div class="row-custom">
          <div class="col-left">
            <div class="affa-feature-icon">
              <!-- 1 -->
              <i class="fa fa-laptop animation" data-animation="animation-fade-in-left"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f0.title') }}</h4>
                <p>{{ $t('features.f0.text') }}</p>
              </div>
            </div>

            <div class="affa-feature-icon">
              <!-- 2 -->
              <i class="fa fa-download animation" data-animation="animation-fade-in-left"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f1.title') }}</h4>
                <p>{{ $t('features.f1.text') }}</p>
              </div>
            </div>

            <div class="affa-feature-icon">
              <!-- 3 -->
              <i class="fa fa-building-o animation" data-animation="animation-fade-in-left"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f2.title') }}</h4>
                <p>{{ $t('features.f2.text') }}</p>
              </div>
            </div>
          </div>

          <div class="col-center">
            <figure class="margin-bottom40 animation" data-animation="animation-fade-in-down">
              <img src="./assets/images/content/landing/feature-3.png" alt="Nexus Black" />
            </figure>
          </div>
          <div class="col-right">
            <div class="affa-feature-icon">
              <!-- 4 -->
              <i class="fa fa-paint-brush animation" data-animation="animation-fade-in-right"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f3.title') }}</h4>
                <p>{{ $t('features.f3.text') }}</p>
              </div>
            </div>

            <div class="affa-feature-icon">
              <!-- 5 -->
              <i class="fa fa-support animation" data-animation="animation-fade-in-right"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f4.title') }}</h4>
                <p>{{ $t('features.f4.text') }}</p>
              </div>
            </div>

            <div class="affa-feature-icon">
              <!-- 6 -->
              <i class="fa fa-shield animation" data-animation="animation-fade-in-right"></i>
              <div class="feature-text">
                <h4>{{ $t('features.f5.title') }}</h4>
                <p>{{ $t('features.f5.text') }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- .row-custom end -->
      </div>
      <!-- .container end -->
    </div>

    <div
      v-if="!currentPageIsPolicy"
      id="counter"
      class="bg-parallax"
      data-parallax="scroll"
      data-speed="0.2"
      data-natural-width="1920"
      data-natural-height="1080"
      data-image-src="./assets/images/content/bg/1.jpg"
    >
      <!-- .bg-overlay -->
      <div class="bg-overlay50 wrap-container8040">
        <!-- .container -->
        <div class="container">
          <!-- .row -->
          <div class="row">
            <div class="col-sm-3">
              <!-- 1 -->
              <div class="affa-counter">
                <img
                  src="./assets/images/content/icon/stats.png"
                  alt="Icon"
                  class="animation"
                  data-animation="animation-fade-in-down"
                />
                <h4>
                  <span>{{ this.sms }}</span>
                </h4>
                <p>{{ $t('counters.sms-forwarded') }}</p>
              </div>
            </div>

            <div class="col-sm-3">
              <!-- 2 -->
              <div class="affa-counter">
                <img
                  src="./assets/images/content/icon/clock.png"
                  alt="Icon"
                  class="animation"
                  data-animation="animation-fade-in-down"
                  data-delay="300"
                />
                <h4><span>200</span>k</h4>
                <p>{{ $t('counters.recording-forwarded') }}</p>
              </div>
            </div>

            <div class="col-sm-3">
              <!-- 3 -->
              <div class="affa-counter">
                <img
                  src="./assets/images/content/icon/coffe-cup.png"
                  alt="Icon"
                  class="animation"
                  data-animation="animation-fade-in-down"
                  data-delay="600"
                />
                <h4><span>64</span>k</h4>
                <p>{{ $t('counters.phone-numbers') }}</p>
              </div>
            </div>

            <div class="col-sm-3">
              <!-- 4 -->
              <div class="affa-counter">
                <img
                  src="./assets/images/content/icon/user.png"
                  alt="Icon"
                  class="animation"
                  data-animation="animation-fade-in-down"
                  data-delay="900"
                />
                <h4><span>256</span>k</h4>
                <p>{{ $t('counters.registered-users') }}</p>
              </div>
            </div>
          </div>
          <!-- .row end -->
        </div>
        <!-- .container end -->
      </div>
      <!-- .bg-overlay end -->
    </div>

    <div v-if="!currentPageIsPolicy" id="contact" class="wrap-container80">
      <!-- .container -->
      <div class="container">
        <div class="sep-border margin-bottom40"></div>
        <!-- separator -->

        <div class="post-heading-center">
          <h2>{{ $t('contact-us.title') }}</h2>
          <p>{{ $t('contact-us.text') }}</p>
        </div>

        <!-- .row -->
        <div class="row">
          <div class="col-md-10 col-lg-8 col-md-offset-1 col-lg-offset-2">
            <form class="affa-form-contact">
              <div class="submit-status" :class="[error ? 'form-error' : 'form-success']" v-if="errorMessage"> {{ errorMessage }} </div>
              <!-- submit status -->
              <input type="text" v-model="name" name="name" :placeholder="$t('contact-us.your-name')" />
              <input type="email" v-model="email" name="email" required :placeholder="$t('contact-us.email-address') + ' *'" />
              <textarea name="message" v-model="message" required :placeholder="$t('contact-us.message') + ' *'"></textarea>
              <br /><br />
              <button type="submit" @click="SendMessage">{{ $t('contact-us.message') }}</button>
            </form>
          </div>
        </div>
        <!-- .row end -->
      </div>
      <!-- .container end -->
    </div>

    <footer id="footer">
      <div class="footer-socials" v-if="!currentPageIsPolicy">
        <div class="" style="display: flex; gap: 15px; justify-content: center; align-items: center; padding: 10px 0 10px">
          <!-- <a href="#" title="Paypal" class="animation" data-animation="animation-bounce-in"
            ><i class="fa fa-paypal"></i
          ></a> -->
<!--            <a href="#" title="BitCoin" class="animation" data-animation="animation-bounce-in">-->
              <i class="fa fa-bitcoin animation" data-animation="animation-bounce-in" style="color: rgb(152,152,152); font-size: 44px"></i>
<!--            </a>-->
<!--            <a href="#" title="LitCoin" class="animation" data-animation="animation-bounce-in">-->
              <img src="./assets/images/icons/Litcoin.png" class="animation" data-animation="animation-bounce-in" height="44" alt="">
<!--            </a>-->
<!--            <a href="#" title="USTD" class="animation" data-animation="animation-bounce-in">-->
              <img src="./assets/images/icons/USTD.png" class="animation" data-animation="animation-bounce-in" height="44" alt="">
<!--            </a>-->
<!--            <a href="#" title="TRON" class="animation" data-animation="animation-bounce-in">-->
              <img src="./assets/images/icons/TRON.png" class="animation" data-animation="animation-bounce-in" height="44" alt="">
<!--            </a>-->
        </div>
      </div>

      <div class="footer-copyright">
        <div class="">
          <div>Globalsms Inc.</div>
          <div class="flex">
            <span class="cursor-pointer policy-link" @click="scrollToTopAndRedirect('refund-policy')">Refund Policy</span>
            |
            <span class="cursor-pointer policy-link" @click="scrollToTopAndRedirect('privacy-policy')">Privacy Policy</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import NavLinks from './navbar/NavLinks'

export default {
  name: 'app',
  components: {
    NavLinks
  },
  data() {
    return {
      token: '',
      sms: '',
      record: '',
      phone: '',
      users: '',

      name: '',
      email: '',
      message: '',
      errorMessage: '',
      error: false,
      currentPageIsPolicy: false
    }
  },
  methods: {
    GetDashbord: function () {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/public/dashboard`, {
          headers: { 'X-Auth-Token': this.token }
        })
        .then((res) => {
          this.sms = res.data.payload.sms_count
          this.phone = res.data.payload
          this.users = res.data.payload.regstered_users
        })
    },
    clearForm: function () {
      this.name = this.email = this.message = ''
    },
    SendMessage: function (event) {
      const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (this.email !== '' && this.email.match(emailRegEx) && this.message !== '') {
        const name = this.name === '' ? '' : `Name: ${this.name}\n`
        const sms = `Subject: Globalsms \n${name}Email: ${this.email} \nMessage: ${this.message}`
        const payload = {
          text: sms,
          username: 'Globalsms Subscriber',
          channel: '#website-contact-form'
        }
        axios.post('https://mattermost.denovolab.com/hooks/fx3oo6dqsfn3mef8fekd89fb9e', payload, {
          headers: { 'Content-Type': 'application/json' }
        })
          .then(() => {
            this.clearForm();
            this.errorMessage = 'Your form has been submitted'
            setTimeout(() => this.errorMessage = '', 5000)
          })
          .catch(() => {
            this.error = true
            this.errorMessage = 'Something went wrong. Try again.'
          })
        event.preventDefault()

      }
    },
    scrollToTopAndRedirect: function (routeName) {
      scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
    checkRoute: function (routeName) {
      this.currentPageIsPolicy = ['refund-policy', 'privacy-policy'].includes(routeName)
    }
  },
  created() {
    this.checkRoute(this.$route.name)
    this.GetDashbord()
  },
  watch: {
    '$route.name' (newRouteName) {
      this.checkRoute(newRouteName)
    }
  }
}
</script>

<style scoped src="../public/css/bootstrap.min.css"></style>
<style>
.cursor-pointer {
  cursor: pointer;
}
.policy-link:hover {
  color: #c1c1c1;
}
</style>
