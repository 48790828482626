<template>
    <div class="locale-changer">
        <dropdown>
            <a class="dropdown-toggle" role="button" >{{langs[$i18n.locale]}} <span class="caret"></span></a>
            <template slot="dropdown">
                <li v-for="(lang, k) in langs" :key="k">
                    <a role="button" v-on:click="$i18n.locale = k">{{ lang }}</a>
                </li>
            </template>
        </dropdown>
     </div>
</template>

<script>
    export default {
        name: 'locale-changer',
        mounted () {
            let langs = {};
            for (let l in this.$i18n.messages) {
                langs[l] = this.$i18n.messages[l].__localeName;
            }
            this.langs = langs;
        },
        created () {
        },
        data () {
            return { langs: {} }
        },
        methods: {
        }
    }
</script>
<style>
    /*.locale-changer.open {*/
        /*position: fixed;*/
    /*}*/
    /*#navigation_affix.show */
</style>