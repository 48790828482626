<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="ml-1 form-row sms-recording-buttons" style="margin-bottom: 10px;">
            <div class="col-auto pull-left">
              <button v-bind:class="[type===1 ? 'btn-primary' : '']"
                      @click="type = 1" class="btn btn-record">Import Numbers
              </button>
            </div>
            <div class="col-auto">
              <button v-bind:class="[type===2 ? 'btn-primary' : '']"
                      @click="type = 2" class="btn btn-record">Manual Entry
              </button>
            </div>
          </div>
          <form v-show="type === 1" enctype="multipart/form-data">
            <div class="row">
              <div class="row ml-2 mt-2">
                <h4>Import File</h4>
                <div class="col-3">
                  <input type="file" id="file" ref="file" placeholder="File name" @change="handleFileUpload()">
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="row ml-2 mt-2">
                <h4>User</h4>
                <div class="col">
                  <select class="form-control" v-model="email" @change="GetUsersId()">
                    <option v-for="item in users" :key="item" :value="item">{{ item }}</option>
                  </select>
                </div>
              </div>
              <div class="row ml-2 mt-2">
                <h4>App</h4>
                <div class="col">
                  <select class="form-control" v-model="app">
                    <option v-for="item in apps" :key="item.uuid" :value="item.uuid">{{ item.app_name }}</option>
                  </select>
                </div>
              </div>
              <div class="row ml-2 mt-2">
                <h4>Duration</h4>
                <div class="col">
                  <input class="form-control" disabled type="text" v-model="order_duration">
                </div>
              </div>
              <button class="btn btn-info ml-4 mt-2" @click.prevent="UploadFile()">
                <i class="fa fa-upload" aria-hidden="true" />
                Upload
              </button>
            </div>
          </form>
          <div v-if="type === 2">
            <div class="container mt-4">
              <div class="form-group row">
                <label for="country-select" class="col-sm-2 col-form-label">{{ $t('global.Country') }}</label>
                <div class="col-sm-4">
                  <select class="form-control" disabled id="country-select">
                    <option>USA</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="users-select" class="col-sm-2 col-form-label">{{ $t('global.User') }}</label>
                <div class="col-sm-4">
                  <select id="users-select" class="form-control" v-model="email" @change="GetUsersId()">
                    <option v-for="item in users" :key="item" :value="item">{{ item }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="app-select" class="col-sm-2 col-form-label">{{ $t('global.App name') }}</label>
                <div class="col-sm-4">
                  <select id="app-select" class="form-control" v-model="app">
                    <option v-for="item in apps" :key="item.uuid" :value="item.uuid">{{ item.app_name }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="number-count-input" class="col-sm-2 col-form-label">{{ $t('global.Numbers Count') }}</label>
                <div class="col-sm-2">
                  <input
                    type="number"
                    class="form-control text-center"
                    :min="1"
                    v-model.number.trim="AppDataForm.count"
                    id="number-count-input"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="number-of-days-input" class="col-sm-2 col-form-label">
                  {{ $t('global.Number of days') }}
                </label>
                <div class="col-sm-2">
                  <input
                    type="Number"
                    class="form-control bg-white text-center"
                    v-model="AppDataForm.duration"
                    :min="1"
                    disabled
                    id="number-of-days-input"
                  />
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm-2 border btn"
                  @click="AppDataForm.use_new = false"
                  :class="{ 'btn-lightgreen': !AppDataForm.use_new }"
                >
                  {{ $t("global.Used") }}
                  <span class="m-0 p-0">($ {{ AppDataForm.app.price_old }})</span>
                </div>
                <div
                  class="col-sm-2 border btn"
                  @click="AppDataForm.use_new = true"
                  :class="{ 'btn-lightgreen': AppDataForm.use_new }"
                >
                  {{ $t("global.Unused") }}
                  <span class="m-0 p-0">($ {{ AppDataForm.app.price_new }})</span>
                </div>
              </div>
              <hr />
              <div class="row justify-content-end no-gutters">
                <span class="" style="font-size:24px;font-weight:700;">
                  <span style="font-weight:700;">Total :</span>
                  $ {{ totalPrice }}
                </span>
              </div>
              <hr />
              <div class="row no-gutters justify-content-end">
                <button class="btn btn-info" @click="createOrder">
                  {{ $t("global.Create") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import VueCookies from 'vue-cookies'

  export default {
    props: ['tabType'],
    data: function () {
      return {
        admintoken: VueCookies.get('admintoken'),
        file_list: [],
        users: [],
        file: '',
        email: '',
        app: '',
        isActive: true,
        type: this.tabType,
        apps: [],
        user_id: null,
        user_id1: '',
        order_duration: 10,
        imported: '',
        date: '',
        time: '',
        FirstInput: '',
        pagesCount: '',
        total: 1,
        currentPage: 1,
        perPage: 10,
        eachSide: 1,
        num: 0,
        count: 10,
        count1: 1,
        boolsort: false,
        sortItem: 'created_on',
        filterColumnStatus: '',
        filterColumnUser: '',
        filterColumnImportUUID: '',
        filterColumnOrderUUID: '',
        UserActive: true,
        AppDataForm: {
          app: { price_new: 0, price_old: 0 },
          use_new: true,
          payUsingAvailBalance: true,
          country: 11,
          duration: 10,
          count: 1
        }
      }
    },
    watch: {
      app(val) {
        if (val) {
          const appItem = this.apps.filter(item => item.uuid === val)
          this.AppDataForm.app.price_new = appItem[0].price_new
          this.AppDataForm.app.price_old = appItem[0].price_old
        }
      }
    },
    computed: {
      totalPrice() {
        if (Object.keys(this.AppDataForm.app).length === 0) {
          return 0;
        }
        let price = this.AppDataForm.use_new
          ? this.AppDataForm.app.price_new
          : this.AppDataForm.app.price_old
        let count = this.AppDataForm.count
        console.log(typeof price)
        console.log(typeof count)
        return Math.round(price * count * 100) / 100
      }
    },
    methods: {
      onShowOrders: function () {
        this.$emit('onShowOrders', 1);
      },
      onShowOrderHistory: function () {
        this.$emit('onShowOrderHistory', 1);
      },
      changeActive: function (active) {
        this.isActive = active
      },
      GetUsers() {
        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
          params: {per_page: 1000},
          headers: {'X-Auth-Token': this.admintoken}
        }).then(async res => {
          const array = res.data.payload.items
          this.users = await array.map(el => el.email).sort()
        })
      },
      GetUsersId: function () {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
          params: {
            email: this.email,
            per_page: 1000
          },
          headers: {'X-Auth-Token': this.admintoken}
        }).then(res => {
          this.user_id = res.data.payload.items[0].user_id
        })
      },
      UploadFile: function () {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('order_duration', this.order_duration);
        formData.append('user_id', this.user_id);
        formData.append('app_uuid', this.app);
        formData.append('duplicates_action', 'override');

        axios.post(`${process.env.VUE_APP_API_BASE_URL}/file/import`,
          formData, {
            headers: {
              'X-Auth-Token': this.admintoken,
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => {
            alert('Success');
            this.onShowOrderHistory();
          },
          () => {
            alert('Error');
          }
        );
      },
      handleFileUpload() {
        this.file = this.$refs.file.files[0];
      },
      Decimal: function () {
        if (this.FirstInput !== '') {
          const regex = /[0-9]/;
          if (!this.FirstInput[this.FirstInput.length - 1].match(regex)) {
            this.FirstInput = this.FirstInput.slice(0, -1)
          }
        }
      },
      GetId: function () {
        axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
          params: {
            email: this.filterColumnUser,
            per_page: 1000
          },
          headers: {
            'X-Auth-Token': this.admintoken
          }
        }).then((res) => {
          if (res.data.payload.items.length === 1)
            this.user_id1 = res.data.payload.items[0].user_id
          else {
            this.user_id1 = ''
          }
        })
      },
      compare(a, b) {
        const appA = a.app_name.toUpperCase()
        const appB = b.app_name.toUpperCase()

        let comparison = 0
        if (appA > appB) {
          comparison = 1
        } else if (appA < appB) {
          comparison = -1
        }
        return comparison
      },
      GetApp() {
        return axios.get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
          params: {per_page: 1000},
          headers: {'X-Auth-Token': this.admintoken}
        }).then(async res => {
          const array = await res.data.payload.items.map(el => {
            return {app_name: el.app_name, uuid: el.uuid, price_new: el.price_new, price_old: el.price_old}
          }).filter(el => el.app_name)
          this.apps = array.sort(this.compare)
        })
      },
      downloadUrl: function (url) {
        console.log('url-->', url)
        window.open(url, '_blank');
      },
      createOrder() {
        const url = `${process.env.VUE_APP_API_BASE_URL}/user/order/create`;
        const params = {
          duration: this.AppDataForm.duration,
          apps: [
            {
              app_uuid: this.app,
              use_new: this.AppDataForm.use_new
                ? "totally_not_included_in_any_order"
                : "any available",
              sorting: 'random',
              ncount: this.AppDataForm.count
            }
          ],
          user_id: this.user_id,
          country_uuid: "11",
          start_date: new Date(),
          duration_type: "one time",
          payment_type: "offline"
        }
        axios
          .post(url, params, { headers: { "X-Auth-Token": this.admintoken } })
          .then(response => {
            console.log('response: ', response)
            alert('Success');
            this.onShowOrders();
          })
          .catch(error => {
            alert('error: ', error)
          })
      }
    },
    created() {
      this.GetUsers()
      this.GetApp()
    }
  }
</script>
<style>

</style>
