<template>
  <div class="locale-changer">
    <dropdown>
      <a class="dropdown-toggle" role="button">{{ $t(`menu.country_number_switcher.${label}`) }}<span class="caret"></span></a>
      <template slot="dropdown">
        <li v-for="(item, k) in numbers" :key="k">
          <a :href="getUrl(item.subdomain)" role="button">{{ $t(`menu.country_number_switcher.${item.label}`) }}</a>
        </li>
      </template>
    </dropdown>
  </div>
</template>

<script>
// import numbers from '../helprers/numbers';

export default {
  name: 'country-number-switcher',
  data () {
    return {

      label: 'us',
      numbers: [
        {
          label: 'us',
          subdomain: 'www'
        },
        {
          label: 'ca',
          subdomain: 'canada'
        }
      ],
    }
  },
  created () {
    const _hostname = window.location.hostname
    const subdomain = _hostname.split('.')[0]
    const hostname = this.numbers.find(item => item.subdomain === subdomain)
    if (subdomain !== 'www' && hostname) {
      this.label = hostname.label
    }
  },
  methods: {
    getUrl(subdomain) {
      return `https://${subdomain}.${process.env.VUE_APP_ROOT_DOMAIN}`
    }
  }
}
</script>
<style>
/*.locale-changer.open {*/
/*position: fixed;*/
/*}*/
/*#navigation_affix.show */
</style>
