import axios from 'axios'
import VueCookies from 'vue-cookies'

const adminToken = VueCookies.get('admintoken')
const URL = process.env.VUE_APP_API_BASE_URL

export default {
  GET_VENDOR_LIST() {
    return axios({
      method: 'GET',
      url: `${URL}/vendor/list`,
      headers: { 'X-Auth-Token': adminToken }
    })
      .then((response) => {
        return response.data.payload.items
      })
      .catch((error) => {
        console.log(error)
      })
  },
  GET_APP_LIST() {
    return axios({
      method: 'GET',
      url: `${URL}/app/list`,
      params: { order_by: 'app_name', order_dir: 'asc' }
    })
      .then((response) => {
        return response.data.payload.items
      })
      .catch((error) => {
        console.log(error)
      })
  },
  SET_ORDER_TASK(payload) {
    return axios({
      method: 'POST',
      url: `${URL}/vendor/order_task`,
      data: payload,
      headers: { 'X-Auth-Token': adminToken }
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  GET_USERS() {
    return axios({
      method: 'GET',
      url: `${URL}/user/list`,
      params: { per_page: 1000, order_by: 'email', order_dir: 'asc' },
      headers: { 'X-Auth-Token': adminToken }
    })
      .then((response) => {
        return response.data.payload.items
      })
      .catch((error) => {
        console.log(error)
      })
  },
  GET_NUMBERS_LIST(uuid) {
    return axios({
      method: 'GET',
      url: `${URL}/public/order/${uuid}/number_list`
    })
      .then((response) => {
        if (response && response.data.success) return response.data.payload.items
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
