<template>
    <div class="row">
        <div class="col-12" style="padding-top: 20px">
            <div class="alert alert-info">
                You can allocate numbers via API and receive SMS for that number for 24 days.
                Within 24 days, if there is no SMS received for the selected APP, the number will be released, and you won’t get charge.
                This is to ensure you only get charged for what you actually received.
            </div>
            <div class="table-responsive">
                <table id="zero_config" class="table table-striped table-bordered" style="font-size: .875rem;">
                    <thead>
                    <tr>
                        <th>APP</th>
                        <th>Charge per SMS received in USD</th>
                        <th>Charge per SMS received in Yuan</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in arr" :key = "item.uuid">
                        <td>{{ item.app_name }}</td>
                        <td>{{ item.price_sms_day }}</td>
                        <td>{{ parseFloat(item.price_sms_day * currency).toFixed(3) }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

 <script>
     import axios from "axios"
     import VueCookies from 'vue-cookies'
     export default {
         props:['currency'],
         name: "UserPayperHistory",
         data:function(){
             return{
                 token:VueCookies.get('usertoken'),
                 arr:[],
                 userSpecialPrices:{}
             }
         },
         methods:{

             getTables:function () {
                let _this = this;
                //check special prices
                 axios
                     .get(`${process.env.VUE_APP_API_BASE_URL}/app_user_price/list`, {
                         headers: {
                             "X-Auth-Token": this.token
                         }
                     })
                     .then(res => {
                         let special_prices = {};
                         Object.keys(res.data.payload.items).forEach(function (k) {
                             special_prices[res.data.payload.items[k].app_uuid] = {
                                 price_old: res.data.payload.items[k].price_old,
                                 price_new: res.data.payload.items[k].price_new,
                                 price_sms_day: res.data.payload.items[k].price_sms_day
                             };
                         });
                         this.userSpecialPrices = special_prices;
                     });

                 axios.get(`${process.env.VUE_APP_API_BASE_URL}/app/list`,{
                     headers:{
                         'X-Auth-Token': this.token,
                     }
                 }).then((res)=>{

                     let Apps = res.data.payload.items;
                     for (let i in Apps) {
                         let app = Apps[i];
                         if(_this.userSpecialPrices[app.uuid]){
                             if(_this.userSpecialPrices[app.uuid].price_new && _this.userSpecialPrices[app.uuid].price_new>0)
                                 Apps[i].price_new = _this.userSpecialPrices[app.uuid].price_new;
                             if(_this.userSpecialPrices[app.uuid].price_old && _this.userSpecialPrices[app.uuid].price_old>0)
                                 Apps[i].price_old = _this.userSpecialPrices[app.uuid].price_old;
                             if(_this.userSpecialPrices[app.uuid].price_sms_day && _this.userSpecialPrices[app.uuid].price_sms_day>0)
                                 Apps[i].price_sms_day = _this.userSpecialPrices[app.uuid].price_sms_day;
                         }
                     }
                     this.arr = Apps;
                     // this.payment_list = res.data.payload.items
                     // this.num = res.data.payload.total
                     // this.total = Math.ceil(this.num/Number(this.perPage))
                 })
             },
         },
         created(){
             this.getTables();
             console.log('userSpecialPrices',this.userSpecialPrices)
         }

     }
 </script>

<style scoped>

</style>
