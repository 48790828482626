<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">


                    <div class="row">
                        <div class="form-group col-md-10">
                            <label for="email">{{ $t('global.Web Hook URL:') }}</label>
                            <input v-model="url" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-2" style="padding-top:29px;">
                            <button v-on:click="save" type="button" class="btn btn-primary">{{ $t('global.Save') }}</button>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import VueCookies from 'vue-cookies'

    export default {
        data:function(){
            return {
                token: VueCookies.get("usertoken"),
                url:'',
                forwardings:false,
            }
        },
        methods:{
            save(){
                let _this = this;
                if (!this.forwardings) {
                    this.forwardings = [ { "delivery": "", "headers": "", "type": "http_get", "data": "", "url": "" } ];
                }
                this.forwardings[0].type = "http_get";
                this.forwardings[0].url = this.url;
                axios.patch(`${process.env.VUE_APP_API_BASE_URL}/user`, {forwardings:this.forwardings}, { headers: { "X-Auth-Token": this.token } }).then((res) => {
                    if(res.data.payload.forwardings && res.data.payload.forwardings.length) {
                        _this.url = res.data.payload.forwardings[0].url || '';
                        _this.forwardings = res.data.payload.forwardings;
                    }
                });
            }
        },
        created(){
            let _this = this;
            axios.get(`${process.env.VUE_APP_API_BASE_URL}/user`, { headers: { "X-Auth-Token": this.token } }).then((res) => {
                if(res.data.payload.forwardings && res.data.payload.forwardings.length) {
                    _this.url = res.data.payload.forwardings[0].url || '';
                    _this.forwardings = res.data.payload.forwardings;
                }
            });
        }
    }
</script>
<style>
</style>
