import axios from 'axios'
import VueCookies from 'vue-cookies'

const token = VueCookies.get('admintoken') || VueCookies.get('usertoken')
const url = process.env.VUE_APP_API_BASE_URL

export default {
  async RECEIVE_SMS(number_uuid) {
    return axios({
      method: 'GET',
      url: `${url}/public/order_number/${number_uuid}/sms_list`,
      headers: { 'X-Auth-Token': token }
    })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        return error?.response?.data?.message
      })
  }
}
