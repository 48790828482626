<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <vue-simple-spinner v-if="loading" message="Loading..." />
            <div class="table-responsive">
              <table class="table table-striped table-bordered" style="font-size: 0.875rem;">
                <thead>
                  <tr>
                    <th @click=";(boolsort = !boolsort), SortColumns('created_on')" style="cursor: pointer;">
                      Created On<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click=";(boolsort = !boolsort), SortColumns('numbers_count')" style="cursor: pointer;">
                      Count<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <!--                    <th @click=";(boolsort = !boolsort), SortColumns('')" style="cursor: pointer;">-->
                    <!--                      Existing-->
                    <!--                    </th>-->
                    <th @click=";(boolsort = !boolsort), SortColumns('app_uuid')" style="cursor: pointer;">
                      App ID<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click=";(boolsort = !boolsort), SortColumns('status')" style="cursor: pointer;">
                      Status<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click=";(boolsort = !boolsort), SortColumns('result')" style="cursor: pointer;">
                      Result<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click=";(boolsort = !boolsort), SortColumns('order_uuid')" style="cursor: pointer;">
                      Order uuid<i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in order_tasks" :key="item.uuid">
                    <td>{{ item.created_on.split('T').join(' ').split('+').shift().split('.').shift() }}</td>
                    <td>{{ item.numbers_count }}</td>
                    <td>{{ item.app_uuid }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.result }}</td>
                    <td>
                      <span
                        @click="showNumbers(item.order_uuid)"
                        data-toggle="modal"
                        data-target="#ModalNumbersList"
                        style="color: royalblue; cursor: pointer;"
                      >
                        {{ item.order_uuid }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NumbersList :list="numbersList" :order="orderUUID" />
  </div>
</template>
<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import Spinner from 'vue-simple-spinner'
import api from './api/vendorApi'
import NumbersList from './modals/NumbersList'
export default {
  name: 'AdminVendorOrderTask',
  data: function () {
    return {
      loading: false,
      token: VueCookies.get('admintoken'),
      isActive: true,
      type: 1,
      bool: false,
      pagesCount: '',
      order_tasks: [],
      total: 1,
      currentPage: 1,
      perPage: 10,
      eachSide: 1,
      num: 0,
      count: 10,
      count1: 1,
      boolsort: false,
      sortItem: '',
      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false
      },
      orderUUID: '',
      numbersList: []
    }
  },
  components: {
    NumbersList,
    // 'v-pagination':VuePagination,
    'vue-simple-spinner': Spinner
  },
  methods: {
    async showNumbers(uuid) {
      this.orderUUID = uuid
      this.numbersList = await api.GET_NUMBERS_LIST(uuid)
      console.log(await api.GET_NUMBERS_LIST(uuid))
    },
    changeActive: function (active) {
      this.isActive = active
    },
    getTables: function () {
      this.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/vendor/order_task/list`, {
          params: {
            order_by: this.sortItem,
            per_page: this.perPage
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.loading = false
          this.order_tasks = res.data.payload.items
          this.num = res.data.payload.total
          this.total = Math.ceil(this.num / Number(this.perPage))
        })
    },
    SortColumns: function (item) {
      this.sortItem = item
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/vendor/order_task/list`, {
          params: {
            order_by: item,
            order_dir: this.boolsort ? 'asc' : 'desc',
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.order_tasks = res.data.payload.items
        })
    }
  },

  created() {
    this.getTables()
  }
}
</script>
<style></style>
