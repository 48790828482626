<template>
	<div class="container" style="height: 80vh">
    <div class="alert alert-info margin-top40 text-center" role="alert">
      Thank you for registration, please check the email we just sent to you.
      You need to click on the Verificaiton link to activate your account in your email.
    </div>
    <div class="text-center">
      <button
        class="btn btn-info d-inline-block"
        style="margin:0 16px"
        @click="$router.push({path:'/login'})">
        {{$t('register-page.sign-in')}}
      </button>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'SuccessRegistration'
	}
</script>

