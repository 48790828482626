<template>
  <div class="modal fade" id="ModalNumbersList" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">List of numbers</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="!list.length" class="alert alert-success" role="alert">
            {{ `There are not numbers from order ${order}` }}
          </div>
          <div v-else class="table-responsive">
            <table class="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td>number</td>
                  <td>sms count</td>
                  <td>start-date</td>
                  <td>end-date</td>
                </tr>
                <tr v-for="(item, i) in list" :key="i">
                  <td>{{ item.number_uuid }}</td>
                  <td>{{ item.sms_count }}</td>
                  <td>{{ moment(item.start_date).format('LL') }}</td>
                  <td>{{ moment(item.end_date).format('LL') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersList',
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true
    },
    order: {
      type: String,
      default: '',
      required: false
    }
  }
}
</script>
