<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <vue-simple-spinner v-if="loading" message="Loading..."></vue-simple-spinner>
                    <div class="col-sm-12 col-md-6">
                        <label>Show
                            <select v-model='perPage' @change="perPagefunc()" class=" form-control form-control-sm d-inline" style="width: 75px;">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entries</label>
                    </div>
                    <div class="mb-3">
                        <div class="form-row">
                            <span>{{ $t('global.User') }}</span>
                            <div class="col-3">
                                <input list="browsers" class="form-control" v-model= "filterUser">

                                <datalist id="browsers">
                                    <option v-for = "user in users" :key="user.user_id">{{user.email}}</option>
                                </datalist>

                            </div>
                            <div class="col-sm-push-0">
                                <button class="btn btn-info" style="height: 34px"  @click="EmptyUsers()"> <i class="fa fa-times"></i></button>
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-info"  @click="FilterUserFunc(filterUser)">Search <i class="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="ml-1 form-row sms-recording-buttons" style="margin-bottom: 10px;">
                        <div class="col-auto">
                            <button @click="MassEdit('activate')"  class="btn btn-primary">{{ $t('global.Activate All') }}</button>
                        </div>
                        <div class="col-auto pull-left">
                            <button @click="MassEdit('inactivate')"  class="btn btn-inactivate">{{ $t('global.Inactivate All') }}</button>
                        </div>
                        <div class="col-auto pull-left">
                            <button @click="MassEdit('delete')"  class="btn btn-danger">{{ $t('global.Delete All') }}</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered" style="font-size: .875rem;">
                            <thead>
                            <tr>
                                <th><input type="checkbox" class="check_all" v-model="selectAll"  value=""></th>
                                <th>User Id</th>
                                <th @click = "boolsort = !boolsort,SortColumns('email')" style="cursor: pointer;">Email <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th>Registered-On</th>
                                <th>Register IP</th>
                                <th>Status</th>
                                <th>Last Login</th>
                                <th>Remaining Credits</th>
                                <!-- <th>Payment History</th>
                                <th>Order History</th>
                                <th>Current Number Assigned</th>
                                <th>Message History</th> -->
                                <th>Action</th>
                            </tr>
                            <!-- <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>#Number</th>
                                <th>Date</th>
                                <th>Order uuid</th>
                                <th># of Number</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Number</th>
                                <th>Order uuid</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Date</th>
                                <th>Order uuid</th>
                                <th>Message</th>
                            </tr> -->
                            </thead>
                            <tbody>
                            <tr v-for="item in user_list" :key="item.user_id">

                                <td><input type="checkbox" v-model="item.selected" :value="item.uuid" class="select_all"></td>
                                <td>{{ item.user_id }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.created_on }}</td>
                                <td>{{ item.register_ip }}</td>
                                <td>
                                    <div class="btn-group">
                                        <button v-if="item.is_active" class="btn btn-success" title="Deactivate" @click="changeStatus(item)">{{ $t('global.Active') }}</button>
                                        <button v-else class="btn btn-danger"  title="Activate" @click="changeStatus(item)">{{ $t('global.Inactive') }}</button>
                                    </div>
                                </td>
                                <td>{{ item.last_login }}</td>
                                <!--<td></td>-->
                                <!-- <td>{{item.payment_count}}</td>
                                <td>{{item.order_count}}</td>
                                <td>{{item.number_count}}</td>
                                <td>{{item.message_count}}</td> -->
                                <td>{{ item.account ? item.account.balance: ''}}</td>
                                <td>
                                    <button class="btn btn-info mr-2" title="Payment History" @click="getPaymentHistory(item.email)" data-toggle="modal" data-target="#PaymentHistory"><i class="mdi mdi-menu"></i></button>
                                    <button class="btn btn-info mr-2" title="Order History" @click="getOrderHistory(item.email)" data-toggle="modal" data-target="#OrderHistory"><i class="mdi mdi-menu"></i></button>
                                    <button class="btn btn-info mr-2" title="Current Number Assigned" @click="getCurrentNumberAssigned(item.email)" data-toggle="modal" data-target="#NumberAssigned"><i class="mdi mdi-menu"></i></button>
                                    <button class="btn btn-info mr-2" title="Message History" @click="getMessageHistory(item.email)" data-toggle="modal" data-target="#MessageHistory"><i class="mdi mdi-menu"></i></button>
                                    <button class="btn btn-danger mr-2" title="Delete User" @click="DeleteEditUser(item.user_id)" data-toggle="modal" data-target="#DeleteUser"><i class="mdi mdi-delete"></i></button>
                                    <button class="btn btn-info mr-2" title="Edit User" @click="getEditUser(item.user_id)" data-toggle="modal" data-target="#EditUser"><i class="mdi mdi-pencil"></i></button>
                                    <button class="btn btn-info mr-2" title="Set Special Price" @click="onAppManagement(item.user_id)"><i class="mdi mdi-pencil"></i></button>
                                    <button class="btn btn-info mr-2" title="Add credit" @click="addCredit(item.user_id)" data-toggle="modal" data-target="#AddCredit"><i class="mdi mdi-cash"></i></button>
                                    <button class="btn btn-info" title="View Credit History" @click="viewCreditHistory(item.user_id)" data-toggle="modal" data-target="#UserCreditHistory"><i class="mdi mdi-cash-multiple"></i></button>

                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                    <div class="row" v-if="num > 10">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                                {{ $t('global.Showing count1 to count of num entries', { count1: count1, count:count,num:num }) }}
                            </div>
                        </div>
                        <nav class="col-sm-12 col-md-7">
                            <ul class="pagination justify-content-end" @click="paginationTables()">
                                <v-pagination :total="total" v-model="currentPage"></v-pagination>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="DeleteUser" tabindex="-1" role="dialog" aria-labelledby="DeleteUserLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="DeleteUserLabel">{{ $t('global.Confirmation') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Please confirm to expire this order.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                        <button type="button" class="btn btn-danger" @click="ConfirmDelUser()" data-dismiss="modal">{{ $t('global.Delete') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="AddCredit" tabindex="-1" role="dialog" aria-labelledby="AddCreditLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="AddCreditLabel">{{ $t('global.Add Credit to user') }} {{ user_id ? getUserEmail:'' }} </h5>
                    </div>
                    <div class="modal-body">
                        <input type="number" v-model="creditAmount" class="form-control" id="creditAmount">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                        <button type="button" class="btn btn-danger" @click="SubmitCreditUser()" data-dismiss="modal">{{ $t('global.Submit') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="EditUser" tabindex="-1" role="dialog" aria-labelledby="EditUser" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="EditUser">{{ $t('global.Edit User') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="form-row pl-2 pr-2 mt-2">
                        <div class="col-md-4">
                            <h4><b><label>{{ $t('global.Email') }}</label></b></h4>
                        </div>
                        <div class="col-md-8">
                            <input v-model="edit_user.email" class="form-control" type="Email" name="email">
                        </div>
                    </div>
                    <div class="form-row pl-2 pr-2 mt-2">
                        <div class="col-md-4">
                            <h4><b><label>{{ $t('global.Password') }}</label></b></h4>
                        </div>
                        <div class="col-md-8">
                            <input v-model="UserPass" class="form-control" type="password" name="email">
                        </div>
                    </div>
                    <div class="form-row pl-2 mt-2 mb-3">
                        <div class="col-md-4">
                            <h4><b><label>Type</label></b></h4>
                        </div>
                        <div class="col">
                            <button v-if="edit_user.is_active" class="btn btn-success" title="Deactivate" @click="edit_user.is_active = !edit_user.is_active">{{ $t('global.Active') }}</button>
                            <button v-else class="btn btn-danger"  title="Activate" @click="edit_user.is_active = !edit_user.is_active">{{ $t('global.Inactive') }}</button>
                        </div>
                        <div class="col">
                            <button v-if="edit_user.is_admin" class="btn btn-success" title="Admin" @click="edit_user.is_admin = !edit_user.is_admin">{{ $t('global.Admin') }}</button>
                            <button v-else class="btn btn-primary"  title="User" @click="edit_user.is_admin = !edit_user.is_admin">{{ $t('global.User') }}</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" data-dismiss="modal" @click="EditUser()">{{ $t('global.Edit') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="PaymentHistory" tabindex="-1" role="dialog" aria-labelledby="PaymentHistoryLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="PaymentHistoryLabel">Payment History</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_payment" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.Date') }}</th>
                                    <th>{{ $t('global.Amount') }}</th>
                                    <th>{{ $t('global.Number uuid') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!payment_history_list.length">
                                    <td style="text-align: center" colspan="3">No Data Found</td>
                                </tr>
                                <tr v-for="item in payment_history_list" :key="item.uuid">
                                    <td>{{ item.paid_on }}</td>
                                    <td>{{ item.amount }}</td>
                                    <td>{{ item.uuid }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="UserCreditHistory" tabindex="-1" role="dialog" aria-labelledby="UserCreditHistoryLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="UserCreditHistoryLabel">Credit History</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.Date') }}</th>
                                    <th>{{ $t('global.Amount') }}</th>
                                    <th>{{ $t('global.Type') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!credit_history_list.length">
                                    <td style="text-align: center" colspan="3">No Data Found</td>
                                </tr>
                                <tr v-for="item in credit_history_list" :key="item.uuid">
                                    <td>{{ item.created_on | moment("YYYY-MM-DD hh:mm:ss") }}</td>
                                    <td>{{ item.amount }}</td>
                                    <td>{{ payment_types[item.payment_gateway] }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="OrderHistory" tabindex="-1" role="dialog" aria-labelledby="OrderHistoryLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="OrderHistoryLabel">{{ $t('global.Order History') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_order" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.Date') }}</th>
                                    <th>{{ $t('global.Order UUID') }}</th>
                                    <th>{{ $t('global.Number uuid') }}</th>
                                    <th>{{ $t('global.Start Date') }}</th>
                                    <th>{{ $t('global.End Date') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!order_history_list.length">
                                    <td style="text-align: center" colspan="5">No Data Found</td>
                                </tr>
                                <tr v-for="item in order_history_list" :key="item.uuid">
                                    <td>{{ item.created_on }}</td>
                                    <td>{{ item.uuid }}</td>
                                    <td>{{ item.numbers_count }}</td>
                                    <td>{{ item.start_date }}</td>
                                    <td>{{ item.end_date }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="NumberAssigned" tabindex="-1" role="dialog" aria-labelledby="NumberAssignedLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="NumberAssignedLabel">Current Number Assigned</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_number" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.Number') }}</th>
                                    <th>{{ $t('global.Order UUID') }}</th>
                                    <th>{{ $t('global.Start Date') }}</th>
                                    <th>{{ $t('global.End Date') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!assign_number_list.length">
                                    <td style="text-align: center" colspan="4">No Data Found</td>
                                </tr>
                                <tr v-for="item in assign_number_list" :key="item.number_uuid">
                                    <td>{{ item.order_num }}</td>
                                    <td>{{ item.number_uuid }}</td>
                                    <td>{{ item.start_date }}</td>
                                    <td>{{ item.end_date }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="MessageHistory" tabindex="-1" role="dialog" aria-labelledby="MessageHistoryLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="MessageHistoryLabel">Message History</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_message" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.From') }}</th>
                                    <th>{{ $t('global.To') }}</th>
                                    <th>{{ $t('global.Date') }}</th>
                                    <th>{{ $t('global.Order UUID') }}</th>
                                    <th>{{ $t('global.Message') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!message_history_list.length">
                                    <td style="text-align: center" colspan="5">No Data Found</td>
                                </tr>
                                <tr v-for="item in message_history_list" :key="item.uuid">
                                    <td>{{ item.from_id }}</td>
                                    <td>{{ item.to }}</td>
                                    <td>{{ item.send_time }}</td>
                                    <td>{{ item.uuid }}</td>
                                    <td>{{ item.msg }}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from "axios";
    import VueCookies from "vue-cookies";
    import VuePagination from "vue-bs-pagination";
    import Spinner from "vue-simple-spinner";


    export default {
        data: function() {
            return {
                edit_user:{},
                user_type:'',
                message_history_list: [],
                assign_number_list: [],
                order_history_list: [],
                loading_message: false,
                loading_number: false,
                loading_order: false,
                loading_payment: false,
                payment_history_list: [],
                credit_history_list:[],
                loading: false,
                selectAll: false,
                token: VueCookies.get("admintoken"),
                adminEmail: VueCookies.get("adminemail"),
                user_list: [],
                active_list: [],
                pagesCount: "",
                total: 1,
                currentPage: 1,
                perPage: 10,
                eachSide: 1,
                num: 0,
                count: 10,
                count1: 1,
                boolsort: false,
                sortItem: "is_active",
                filterUser: "",
                creditAmount:0,
                user_id: Number,

                UserPass: "",
                /*UserEmail: "",
                UserActive: false,
                UserIsAdmin: false,
                UserIsReseller:false,
                UserType:'user',*/

                users: [],
                payment_types:{
                    "offline":"Admin Credit",
                    "paypal":"Paypal Payment",
                    "coinbase":"CoinPayment",
                    "stripe":"Stripe Payment"
                }
            };
        },
        components: {
            "v-pagination": VuePagination,
            "vue-simple-spinner": Spinner
        },
        watch: {
            selectAll: function() {
                for (var i in this.user_list) {
                    this.user_list[i].selected = this.selectAll;
                }
            }
        },
        methods: {
            getPaymentHistory: function(email) {
                this.payment_history_list = [];
                this.loading_payment = true;
                axios
                    .get(
                        `${process.env.VUE_APP_API_BASE_URL}/user/payment/list?user_name=${email}`,

                        {
                            params: {
                                type:'payment'
                            },
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(res => {
                        this.loading_payment = false;
                        this.payment_history_list = res.data.payload.items;
                    })
                    .catch(() => {});
            },
            getOrderHistory: function(email) {
                this.order_history_list = [];
                this.loading_order = true;
                axios
                    .get(
                        "http://51.79.78.109/api_gsim/v1/user/order/list?user_name=" +
                        email,

                        {
                            params: {
                                order_by: 'created_on',
                                order_dir: "desc"
                            },
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(res => {
                        this.loading_order = false;
                        this.order_history_list = res.data.payload.items;
                    })
                    .catch(() => {});
            },
            getCurrentNumberAssigned: function(email) {
                this.loading_number = true;
                axios
                    .get(
                        `${process.env.VUE_APP_API_BASE_URL}/user/number/list?is_active=true&user_name=${email}`,
                        {
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(res => {
                        this.loading_number = false;
                        this.assign_number_list = res.data.payload.items;
                    })
                    .catch(() => {});
            },
            getMessageHistory: function(email) {
                this.loading_message = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/sms/list?user_name=${email}`, {
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading_message = false;
                        this.message_history_list = res.data.payload.items;
                    })
                    .catch(() => {});
            },
            changeStatus(item) {
                this.loading = true;
                axios
                    .patch(
                        `${process.env.VUE_APP_API_BASE_URL}/user/${item.user_id}`,
                        { is_active: !item.is_active },
                        {
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(() => {
                        this.loading = false;
                        item.is_active = !item.is_active;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            MassEdit() {},
            getTables: function() {
                var order_dir;
                if (this.boolsort == false) {
                    order_dir = "desc";
                } else {
                    order_dir = "asc";
                }
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            email: this.filterUser,
                            order_by: this.sortItem,
                            order_dir: order_dir,
                            per_page: this.perPage,
                            page: this.currentPage - 1
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.user_list = res.data.payload.items;
                        this.num = res.data.payload.total;
                        this.total = Math.ceil(this.num / Number(this.perPage));
                    });
            },
            getActive: function() {
                axios
                    .get(
                        `${process.env.VUE_APP_API_BASE_URL}/user/payment/list?user_name=samvel.gabrielyan100@gmail.com`,
                        {
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(() => {
                        // console.log(res.data)
                    });
            },
            // getPayment:function(email){
            //     var newstr = email.replace('@', '%40');
            //     // console.log(newstr)
            //     axios.get(`${process.env.VUE_APP_API_BASE_URL}/user/number/list?is_active=true&user_name=274278678%40qq.com`,{
            //         headers:{
            //             'X-Auth-Token': this.token,
            //         }
            //     }).then((res)=>{
            //         console.log(res.data)
            //     })
            // },
            perPagefunc: function() {
                var order_dir;
                if (this.boolsort == false) {
                    order_dir = "desc";
                } else {
                    order_dir = "asc";
                }
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            email: this.filterUser,
                            order_by: this.sortItem,
                            order_dir: order_dir,
                            per_page: this.perPage,
                            page: this.currentPage - 1
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.user_list = res.data.payload.items;
                        this.num = res.data.payload.total;
                        this.total = Math.ceil(this.num / Number(this.perPage));
                        this.count1 = Number((this.currentPage - 1) * this.perPage + 1);
                        this.count = this.count1 + this.user_list.length - 1;
                    });
            },
            paginationTables: function() {
                var order_dir;
                if (this.boolsort == false) {
                    order_dir = "desc";
                } else {
                    order_dir = "asc";
                }
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            email: this.filterUser,
                            order_by: this.sortItem,
                            order_dir: order_dir,
                            per_page: this.perPage,
                            page: this.currentPage - 1
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.user_list = res.data.payload.items;
                        this.count1 = Number((this.currentPage - 1) * this.perPage + 1);
                        this.count = this.count1 + this.user_list.length - 1;
                       // this.user_list = res.data.payload.items.filter((item) => { return item.is_reseller != true })
                    });
            },
            SortColumns: function(item) {
                var order_dir;
                if (this.boolsort == false) {
                    order_dir = "desc";
                } else {
                    order_dir = "asc";
                }
                this.sortItem = item;
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            email: this.filterUser,
                            order_by: item,
                            order_dir: order_dir,
                            per_page: this.perPage,
                            page: this.currentPage - 1
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.user_list = res.data.payload.items;
                        //this.user_list = res.data.payload.items.filter((item) => { return item.is_reseller != true })
                    });
            },
            FilterUserFunc(user) {
                var order_dir = 'desc';
                var order_by = 'is_active';
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            order_by: order_by,
                            order_dir: order_dir,
                            per_page: this.perPage,
                            page: this.currentPage - 1,
                            email: user
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.user_list = res.data.payload.items;
                        this.num = res.data.payload.total;
                        this.total = Math.ceil(this.num / Number(this.perPage));
                    });
            },
            EmptyUsers(){
                this.filterUser = '';
            },
            DeleteEditUser: function(id) {
                this.user_id = id;
            },
            ConfirmDelUser: function() {
                axios
                    .delete(`${process.env.VUE_APP_API_BASE_URL}/user/${this.user_id}`, {
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(
                        () => {
                            this.getTables();
                            alert("Success");
                        },
                        () => {
                            alert("Error");
                        }
                    );
            },
            getEditUser: function(id) {
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/${id}`, {
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.edit_user = {
                            ...res.data.payload,
                        }
                    });
                this.user_id = id;


            },
            EditUser: function() {
                axios
                    .patch(
                        `${process.env.VUE_APP_API_BASE_URL}/user/${this.user_id}`,
                        {
                            is_active: this.edit_user.is_active,
                            passwd: this.UserPass,
                            email: this.edit_user.email,
                            is_admin: this.edit_user.is_admin,
                        },
                        {
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(
                        () => {
                            this.getTables();
                            alert("Success");
                        },
                        () => {
                            alert("Error");
                        }
                    );
            },
            GetUsers: function() {
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
                        params: {
                            per_page: 1000
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.users = res.data.payload.items;
                    });

            },
            onAppManagement:function (user_id) {
                this.$emit('onAppManagement', user_id);
            },
            addCredit: function(id) {
                this.user_id = id;
            },
            SubmitCreditUser: function(){
                if(this.creditAmount<0){
                    alert("Credit amount should be more than 0");
                }else{
                    axios
                        .post(
                            `${process.env.VUE_APP_API_BASE_URL}/user/credit/create`,
                            {
                                "amount": this.creditAmount,
                                "user_id": this.user_id,
                            },
                            {
                                headers: {
                                    "X-Auth-Token": this.token
                                }
                            }
                        )
                        .then(
                            () => {
                                alert("Credit added successfully");
                            },
                            () => {
                                alert("Error");
                            }
                        );

                }
            },
            viewCreditHistory: function (user_id) {
                axios
                    .get(`${process.env.VUE_APP_API_BASE_URL}/user/payment/list`, {
                        params: {
                            per_page: 60,
                            user_id:user_id,
                            type:'credit'
                        },
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(res => {
                        this.credit_history_list = res.data.payload.items;
                    });
            }


        },
        computed:{
            getUserEmail: function() {
                let user_id = this.user_id
                if (user_id) {
                    let active_user = this.users.filter((item) => {
                            return item.user_id == user_id;
                    });
                    if(active_user.length)
                    return active_user[0].email;
                }
                return '--';
            },

        },
        created() {
            this.getTables();
            this.GetUsers();
            this.getActive();
        }
    };
</script>
<style>
</style>
