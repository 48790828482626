<template>
  <div>
    <div class="preloader">
      <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <div class="navbar-header">
            <!-- This is for the sidebar toggle which is visible on mobile only -->
            <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="#">
              <i class="ti-menu ti-close"></i>
            </a>
            <!-- ============================================================== -->
            <!-- Logo -->
            <!-- ============================================================== -->
            <!--<a class="navbar-brand" href="/user" style="height: 64px;">-->
            <!-- Logo icon -->
            <b class="logo-icon" style="vertical-align: center;">
              <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
              <!-- Dark Logo icon -->
              <a href="/user">
                <img
                  src="../assets/images/logo_v4.png"
                  style="width: 200px; height: 65px;"
                  alt="homepage"
                  class="dark-logo"
                />
              </a>
              <!-- Light Logo icon -->
              <!--<img src="../../public/assets/admin/assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />-->
            </b>
            <!--End Logo icon -->
            <!-- Logo text -->
            <!--<span class="logo-text">-->
            <!--&lt;!&ndash; dark Logo text &ndash;&gt;-->
            <!--<img src="../../public/assets/admin/assets/images/logo-text.png" alt="homepage" class="dark-logo" />-->
            <!--&lt;!&ndash; Light Logo text &ndash;&gt;-->
            <!--<img src="../../public/assets/admin/assets/images/logo-light-text.png" class="light-logo" alt="homepage" />-->
            <!--</span>-->
            <!--</a>-->
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Toggle which is visible on mobile only -->
            <!-- ============================================================== -->
            <a
              class="topbartoggler d-block d-md-none waves-effect waves-light"
              href="#"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="ti-more"></i>
            </a>
          </div>
          <!-- ============================================================== -->
          <!-- End Logo -->
          <!-- ============================================================== -->
          <div class="navbar-collapse collapse" id="navbarSupportedContent">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-left mr-auto">
              <!-- <li class="nav-item d-none d-md-block"><a class="nav-link sidebartoggler waves-effect waves-light" href="#" data-sidebartype="mini-sidebar"><i class="mdi mdi-menu font-24"></i></a></li> -->

              <!-- ============================================================== -->
              <!-- Search -->
              <!-- ============================================================== -->
              <!-- <li class="nav-item search-box"> <a class="nav-link waves-effect waves-dark" href="#"><i class="ti-search" style="line-height: 64px;"></i></a>
                  <form class="app-search position-absolute">
                      <input type="text" class="form-control" placeholder="Search &amp; enter"> <a class="srh-btn"><i class="ti-close"></i></a>
                  </form>
              </li>-->
            </ul>
            <!-- ============================================================== -->
            <!-- Right side toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-right">
              <!-- ============================================================== -->
              <!-- Comment -->
              <!-- ============================================================== -->

              <li class="nav-item dropdown">
                <button
                  type="button"
                  class="btn btn-link nav-link dropdown-toggle waves-effect waves-dark"
                  data-toggle="modal"
                  data-target="#RemainingCreditModal"
                >
                  <strong class="mr-2">{{ $t('global.Remaining Credit') }}</strong>
                  <strong>
                    <span>$ </span>
                    <span>{{ account.balance }}</span>
                  </strong>
                </button>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle waves-effect waves-dark"
                  href="index.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-bell font-24" />
                </a>
                <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown w-25">
                  <span class="with-arrow">
                    <span class="bg-primary" />
                  </span>
                  <ul class="list-style-none">
                    <li>
                      <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">{{ total_not }} {{ $t('global.New') }}</h4>
                        <span class="font-light">
                          {{ $t('global.Notifications') }}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="message-center notifications">
                        <!-- Message -->
                        <a
                          href="#"
                          v-for="(item, i) in notifications"
                          v-bind:key="i"
                          @click="item_not = item"
                          data-toggle="modal"
                          data-target="#NotConf"
                          class="message-item"
                        >
                          <span class="btn btn-danger btn-circle">
                            <i class="fa fa-link" />
                          </span>
                          <div class="mail-contnet">
                            <h5 class="message-title">{{ item.subject }}</h5>
                            &nbsp;
                            <span class="mail-desc">{{ item.content }}</span
                            >&nbsp;
                            <span class="time">{{ item.sent_on }}</span>
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <a class="nav-link text-center m-b-5" href="#">
                        &nbsp;
                        <strong>{{ $t('global.Check all notifications') }}</strong>
                        <i class="fa fa-angle-right" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- ============================================================== -->
              <!-- End Comment -->
              <!-- ============================================================== -->
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="index.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="../../public/assets/admin/assets/images/users/1.jpg"
                    alt="user"
                    class="rounded-circle"
                    width="31"
                    style="margin-top: 15px;"
                  />
                </a>

                <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                  <span class="with-arrow">
                    <span class="bg-primary" />
                  </span>
                  <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                    <div class>
                      <img
                        src="../../public/assets/admin/assets/images/users/1.jpg"
                        alt="user"
                        class="img-circle"
                        width="60"
                      />
                    </div>

                    <div class="ml-3 mt-2">
                      <p class="m-b-0">{{ user_email }}</p>
                    </div>
                  </div>

                  <div class="dropdown-divider"></div>

                  <a class="dropdown-item" href="#" @click="ChangeComponent('paymenthistory')">
                    {{ $t('global.Payment History') }}
                  </a>
                  <a class="dropdown-item" href="#" @click="OpenTutorial($i18n.locale)">{{ $t('global.Tutorial') }}</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click="LogOut()">
                    <i class="fa fa-power-off m-r-5 m-l-5" />&nbsp; Logout
                  </a>
                </div>
              </li>

              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="index.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{ langs[$i18n.locale] }}
                </a>
                <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                  <span class="with-arrow">
                    <span class="bg-primary" />
                  </span>
                  <div class="dropdown-divider"></div>
                  <a v-for="(lang, k) in langs" :key="k" v-on:click="langChanged(k)" class="dropdown-item">
                    {{ lang }}
                  </a>
                  <div class="dropdown-divider"></div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <user-notification :item_not="item_not" />

      <!-- ============================================================== -->
      <!-- End Topbar header -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar">
          <!-- Sidebar navigation-->
          <nav class="sidebar-nav">
            <ul id="sidebarnav">
              <!-- User Profile-->
              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" href="/user" aria-expanded="false">
                  <i class="mdi mdi-view-dashboard" />
                  <span class="hide-menu">{{ $t('global.Dashboard') }}</span>
                </a>
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link two-column waves-effect waves-dark" @click="ChangeComponent('messages')">
                  <i class="mdi mdi-message" />
                  <span class="hide-menu">{{ $t('global.Messages') }}</span>
                </a>
              </li>

              <li class="sidebar-item mega-dropdown">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('mynumbers')"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-widgets"></i>
                  <span class="hide-menu">{{ $t('global.My numbers') }}</span>
                </a>
              </li>

              <li class="sidebar-item">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('orderhistory')"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-collage" />
                  <span class="hide-menu">{{ $t('global.Order History') }}</span>
                </a>
              </li>
              <li class="sidebar-item">
                <a class="sidebar-link has-arrow waves-effect waves-dark" href="#">
                  <i class="mdi mdi-receipt" />
                  <span class="hide-menu">{{ $t('global.Buy now') }}</span>
                </a>
                <ul aria-expanded="false" class="collapse first-level">
                  <li class="sidebar-item">
                    <a
                      class="sidebar-link waves-effect waves-dark"
                      href="#"
                      @click="ChangeComponent('BuyReservicesNumbers')"
                    >
                      <i class="mdi mdi-collage" />
                      <span class="hide-menu">{{ $t('global.Buy reserve numbers') }}</span>
                    </a>
                  </li>
                  <!--                  <li class="sidebar-item">-->
                  <!--                    <a class="sidebar-link waves-effect waves-dark" href="#" @click="ChangeComponent('PayPerUsage')">-->
                  <!--                      <i class="mdi mdi-receipt" />-->
                  <!--                      <span class="hide-menu">-->
                  <!--                        {{ $t('global.Pay per usage') }}-->
                  <!--                      </span>-->
                  <!--                    </a>-->
                  <!--                  </li>-->
                </ul>
              </li>

              <li class="sidebar-item">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('pricing')"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-collage" />
                  <span class="hide-menu">{{ $t('global.Pricing') }}</span>
                </a>
              </li>

              <li class="sidebar-item mega-dropdown">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('webhook')"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-webhook"></i>
                  <span class="hide-menu">{{ $t('global.Web Hook') }}</span>
                </a>
              </li>
            </ul>
          </nav>
          <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
      </aside>
      <!-- ============================================================== -->
      <!-- End Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Page wrapper  -->
      <!-- ============================================================== -->
      <div class="page-wrapper" style="display: block;">
        <div class="page-breadcrumb">
          <div class="row">
            <div class="col-12 align-self-center">
              <h4 class="page-title" v-if="comp_name === 'dashboard'">
                {{ $t('global.Dashboard') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'messages'">
                {{ $t('global.Massages') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'webhook'">
                {{ $t('global.Web Hook') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'buy' || comp_name === 'buy1' || comp_name === 'buy2'">
                {{ $t('global.Buy') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'mynumbers'">
                {{ $t('global.My Numbers') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'orderhistory'">
                {{ $t('global.Order History') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'paymenthistory'">
                {{ $t('global.Payment History') }}
              </h4>
              <h4 class="page-title" v-if="comp_name === 'pricing'">
                {{ $t('global.Pricing') }}
              </h4>

              <h4 style="width: 50%; float: left;" class="page-title" v-if="comp_name === 'BuyReservicesNumbers'">
                {{ buyType ? $t('global.Reserve Numbers') : $t('global.Gateway') }}
              </h4>
              <h4 style="width: 50%; float: left;" class="page-title" v-if="comp_name === 'PayPerUsage'">
                {{ buyType ? $t('global.Pay per SMS received') : $t('global.Gateway') }}
              </h4>
              <button
                style="float: right;"
                class="btn btn-primary"
                v-if="(comp_name === 'BuyReservicesNumbers' || comp_name === 'PayPerUsage') && !buyType"
                @click="buyType = true"
              >
                Back
              </button>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <!--<li class="breadcrumb-item"><a href="index.html#">Home</a></li>-->
                    <!--<li class="breadcrumb-item active" aria-current="page">Library</li>-->
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->

        <div class="container-fluid">
          <buy
            v-if="comp_name === 'BuyReservicesNumbers' || comp_name === 'PayPerUsage'"
            :type="comp_name === 'BuyReservicesNumbers' ? 'ReservicesNumbers' : 'PerUsage'"
            :buyType="buyType"
            @fromChild="inParent"
          />

          <user-dashboard v-if="comp_name === 'dashboard'" />
          <user-messages v-if="comp_name === 'messages'" />
          <user-my-numbers v-if="comp_name === 'mynumbers'" />
          <user-order-history v-if="comp_name === 'orderhistory'" />
          <user-payment-history v-if="comp_name === 'paymenthistory'" />
          <user-pricing v-if="comp_name === 'pricing'" />
          <user-credit v-if="comp_name === 'user-credit'" />
          <user-add-credit v-if="comp_name === 'user-add-credit'" />
          <webhook v-if="comp_name === 'webhook'" />
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- customizer Panel -->
    <!-- ============================================================== -->

    <div id="RemainingCreditModal" tabindex="-1" class="modal fade" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t('global.Please enter the amount to add to your credit') }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" ref="creditModalBtn">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div
              v-if="credit_order.amount < 0.3"
              class="alert alert-warning mb-4"
              role="alert"
              style="font-size: 16px;"
            >
              Your amount for credit must be more than 0.3 USD
            </div>
            <div class="form-group row justify-content-center align-items-center mb-4">
              <label for="amountInput" class="col-sm-2 col-form-label">Amount</label>
              <div class="col-sm-2">
                <input
                  id="amountInput"
                  class="form-control"
                  type="number"
                  step="0.5"
                  v-model.number="credit_order.amount"
                  style="width: 100px;"
                />
              </div>
              <span style="font-size: 16px; margin-left: 40px;">USD</span>
            </div>
            <div class="row justify-content-center" style="margin-bottom: 24px;" v-if="credit_order.amount >= 0.3">
              <div class="col-8 pr-5">
                <CoinpaymentsButton
                  :token="token"
                  :amount="credit_order.amount.toString()"
                  :order="credit_order"
                  v-on:onCreateTransaction="onPayment"
                  v-on:onError="(e) => console.error('ERROR', e.message)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-windows"></div>
  </div>
</template>
<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import CoinpaymentsButton from 'coinpayments-button'
// import PayPal from 'vue-paypal-checkout'

import router from '../router.js'

import Buy from './Buy.vue'
import UserDashboard from './UserDashboard.vue'
import UserMessages from './UserMessages.vue'
import UserMyNumbers from './UserMyNumbers.vue'
import UserOrderHistory from './UserOrderHistory.vue'
import UserPaymentHistory from './UserPaymentHistory.vue'
import UserPricing from './UserPricing.vue'
import UserNotification from './UserNotification.vue'
import UserCredit from './UserCredit'
import UserAddCredit from './UserAddCredit.vue'
import WebHook from './WebHook.vue'
import eventBus from '../event_bus.js'

export default {
  mounted() {
    let langs = {}
    for (let l in this.$i18n.messages) {
      langs[l] = this.$i18n.messages[l].__localeName
    }
    this.langs = langs
    if (this.lang !== 'null') {
      this.langChanged(this.lang)
    }
  },
  data: function () {
    return {
      paypal: {
        sandbox: process.env.PAYPAL_SANDBOX_ID,
        production: process.env.PAYPAL_PRODUCTION__ID
      },
      paypalButton: {
        size: 'responsive',
        shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'paypal',
        tagline: false
      },
      langs: {},
      comp_name: 'dashboard',
      lang: VueCookies.get('lang') != null ? VueCookies.get('lang') : 'en',
      user_email: VueCookies.get('useremail'),
      token: VueCookies.get('usertoken'),
      notifications: [],
      credit_order: {
        type: 'PaymentOnly',
        amount: 1
      },
      total_not: '',
      item_not: '',
      account: {},
      buyType: true,
      tutorial: {
        href: 'https://doc.globalsms.io/',
        href2: 'https://doc.globalsms.io/',
        name: 'English Tutorial',
        name2: 'Chiness Tutorial'
      }
    }
  },
  components: {
    buy: Buy,
    'user-dashboard': UserDashboard,
    'user-messages': UserMessages,
    'user-my-numbers': UserMyNumbers,
    'user-order-history': UserOrderHistory,
    'user-payment-history': UserPaymentHistory,
    'user-notification': UserNotification,
    'user-credit': UserCredit,
    'user-add-credit': UserAddCredit,
    'user-pricing': UserPricing,
    webhook: WebHook,
    CoinpaymentsButton
    // PayPal
  },
  methods: {
    // paymentCanceled(event) {
    //   console.log('paymentCanceled: ', event)
    // },
    // paymentCompleted(event) {
    //   console.log('paymentCompleted: ', event);
    // },
    inParent() {
      this.buyType = !this.buyType
    },
    ChangeComponent: function (comp_name) {
      this.comp_name = comp_name
    },
    GetNotifications: function () {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/notification/list`, {
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.notifications = res.data.payload.items
          this.total_not = res.data.payload.total
        })
    },
    LogOut: function () {
      VueCookies.remove('token')
      VueCookies.remove('useremail')
      VueCookies.remove('lang')
      router.push({ name: 'home' })
      location.reload()
    },
    OpenTutorial: function (lang) {
      let url
      url = lang === 'en' ? this.tutorial.href : this.tutorial.href2
      window.open(url, '_blank')
    },
    langChanged: function (lang) {
      this.$i18n.locale = lang
      VueCookies.set('lang', lang)
    },
    onPayment(tx) {
      console.log('created new transaction', tx)
      const params = {
        width: 800,
        height: 700,
        menubar: 'no',
        toolbar: 'no',
        location: 'yes',
        status: 'yes',
        resizable: 'yes',
        scrollbars: 'yes'
      }
      this.$refs.creditModalBtn.click()
      window.open(
        tx.checkout_url,
        '_blank',
        Object.keys(params)
          .map((k) => `${k}=${params[k]}`)
          .join(',')
      )
    }
  },
  created() {
    const _this = this
    this.GetNotifications()
    eventBus.$on('__router:to', this.ChangeComponent)
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/user`, {
        headers: { 'X-Auth-Token': this.token }
      })
      .then((res) => {
        _this.account = res.data.payload.account
      })
  },
  destroyed() {
    eventBus.$off('__router:to', this.ChangeComponent)
  }
}
</script>
<style></style>
