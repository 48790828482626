<template>
    <div class="row">To Be Released
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <vue-simple-spinner v-if="loading" message="Loading..."></vue-simple-spinner>

                    <div  class="modal fade" id="NumberHistoryConf" tabindex="-1" role="dialog" aria-labelledby="NumberHistoryConf" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" style="max-width: 1500px">
                            <div class="modal-content" style="width: 1500px;">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="NumberHistoryConf1">View History Order</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Number uuid</th>
                                            <th>Order uuid</th>
                                            <th>App</th>
                                            <th>End Date</th>
                                            <th>Start Date</th>
                                            <th>Email</th>
                                        </tr>
                                        </thead>
                                        <tbody v-for="item in items" :key="item.number_uuid">
                                        <tr>
                                            <td>{{item.number_uuid}}</td>
                                            <td>{{item.order_uuid}}</td>
                                            <td>{{item.app_uuid}}</td>
                                            <td>{{item.end_date.split('T').join(' ').split('+').shift()}}</td>
                                            <td>{{item.start_date.split('T').join(' ').split('+').shift()}}</td>
                                            <td>{{item.user_name}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  class="modal fade" id="ShowMessageConf" tabindex="-1" role="dialog" aria-labelledby="ShowMessageConf" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" style="max-width: 1500px">
                            <div class="modal-content" style="width: 1500px;">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="ShowMessageConf1">Show Message</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Number</th>
                                            <th>Message</th>
                                            <!-- <th>Number uuid</th>
                                            <th>Order uuid</th>
                                            <th>App</th>
                                            <th>End Date</th>
                                            <th>Start Date</th>
                                            <th>Email</th> -->
                                        </tr>
                                        </thead>
                                        <tbody v-for="item in sms_list" :key="item.number_uuid">
                                        <tr>
                                            <td>{{item.to}}</td>
                                            <td>{{item.msg}}</td>
                                            <!-- <td>{{item.number_uuid}}</td>
                                            <td>{{item.order_uuid}}</td>
                                            <td>{{item.app_uuid}}</td>
                                            <td>{{item.end_date.split('T').join(' ').split('+').shift()}}</td>
                                            <td>{{item.start_date.split('T').join(' ').split('+').shift()}}</td>
                                            <td>{{item.user_name}}</td> -->
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  class="modal fade" id="ShowMessage" tabindex="-1" role="dialog" aria-labelledby="ShowMessage" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" style="max-width: 1500px">
                            <div class="modal-content" style="width: 1500px;">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="ShowMessageConf1">Show Message</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                  <div class="col-md-12">
                                    <div class="form-row">
                                      <span>Date From</span>
                                      <div class="col-md-2">
                                          <date-picker v-model= "smsStartTime" :config="options"></date-picker>
                                      </div>
                                      <span>Date To</span>
                                      <div class="col-md-2">
                                          <date-picker v-model= "smsEndTime" :config="options"></date-picker>
                                      </div>

                                      <div class="col-md-2">
                                          <button class="btn btn-info" @click="getNumberSmsWithDate()">Search <i class="fa fa-search"></i></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="sms_count != 0" class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Number</th>
                                            <th>Message</th>
                                        </tr>
                                        </thead>
                                        <tbody v-for="item in sms_list" :key="item.number_uuid">
                                        <tr>
                                            <td>{{item.to}}</td>
                                            <td>{{item.msg}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else class="col-md-12">
                                    <h5> Nothing to show</h5>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-12 col-md-6">
                        <label>Show
                        <select v-model='perPage' @change="perPagefunc()" class=" form-control form-control-sm d-inline" style="width: 75px;">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        entries</label>
                    </div>
                    <div class="mb-3">
                        <div class="form-row">

                            <span>Number</span>
                            <div class="col-3">
                                  <input type="text" list="browser1" class="form-control" v-model="FilterNumber">
                                <datalist id="browser1">
                                    <option v-for = "number in numbers" :key="number.number_uuid">{{number.number_uuid}}</option>
                                </datalist>
                            </div>
                            <span>Enable</span>
                            <div class="col-1">
                                <select v-model='FilterEnabled' class=" form-control" >
                                    <option v-for="(option, i) in enable_options" v-bind:value="option.value" v-bind:key="i">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-1">
                                <button class="btn btn-info" @click="filterColumnOrder()">Search <i class="fa fa-search"></i></button>
                            </div>

                            <div class="col-auto pull-left">
                                <button @click="onImportNumbers" class="btn btn-record">Import Numbers</button>
                            </div>
                        </div>

                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered" style="font-size: .875rem;">
                            <thead>
                                <tr>
                                    <th class="align-top" rowspan="2">Action</th>
                                    <th @click = "boolsort = !boolsort,SortColumns('number_uuid')" style="cursor: pointer;">Number <i class="fa fa-sort" aria-hidden="true"></i></th>
                                    <th @click = "boolsort = !boolsort,SortColumns('entered_on')" style="cursor: pointer;">Entered On <i class="fa fa-sort" aria-hidden="true"></i></th>
                                    <th @click = "boolsort = !boolsort,SortColumns('released_on')" style="cursor: pointer;">Last Released On <i class="fa fa-sort" aria-hidden="true"></i></th>
                                    <th >Enable </th>
                                    <th @click = "boolsort = !boolsort,SortColumns('is_active')" style="cursor: pointer;">Is active <i class="fa fa-sort" aria-hidden="true"></i></th>
                                    <th @click = "boolsort = !boolsort,SortColumns('created_on')" style="cursor: pointer;">Created On <i class="fa fa-sort" aria-hidden="true"></i></th>
                                    <th>View History Order</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for = "number in numbers" :key = "number.uuid">
                                  <td><button data-toggle="modal" data-target="#ShowMessage" @click="getSmsHistory(number.order_uuid,number.number_uuid)" type="button" class="btn btn-outline-secondary">Show Messages</button></td>
                                    <td>{{ number.number_uuid }}</td>
                                    <td>{{ number.entered_on? number.entered_on.split('T').join(' ').split('+').shift(): '' }}</td>
                                    <td>{{ number.released_on? number.released_on.split('T').join(' ').split('.').shift(): '' }}</td>
                                    <td>{{number.enabled? 'True': 'False'}}</td>
                                    <td>{{number.is_active? 'True': 'False'}}</td>
                                    <td>{{ number.created_on? number.created_on.split('T').join(' ').split('.').shift(): '' }}</td>
                                    <td><button data-toggle="modal" data-target="#NumberHistoryConf" @click="getNumbersHistory(number.number_uuid)" type="button" class="btn btn-outline-secondary">view history</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                            Showing {{ count1 }} to {{ count }} of {{ num }} entries
                            </div>
                        </div>
                        <nav class="col-sm-12 col-md-7">
                          <ul class="pagination justify-content-end" @click="paginationTables()">
                            <v-pagination :total="total" v-model="currentPage"></v-pagination>
                          </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import VuePagination from "vue-bs-pagination";
import Spinner from "vue-simple-spinner";
import datePicker from 'vue-bootstrap-datetimepicker';

export default {
  data: function() {
    return {
      loading: false,
      token: VueCookies.get("admintoken"),
      numbers: [],
      pagesCount: "",
      total: 1,
      currentPage: 1,
      perPage: 10,
      eachSide: 1,
      num: 0,
      filterColumn: "",
      smsStartTime: "",
      smsEndTime: "",
      //FilterEmail: "",
      searchOrder: "",
      number: "",
      FilterNumber: "",
        FilterEnabled:"",

      count: 10,
      count1: 1,
      boolsort: false,
      sortItem: "created_on",
      is_active: true,
      numberCount: 0,
      items: [],
      sms_list: [],

      users: [],
      options: {
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
        selectedNumber:null,
        sms_count:0,
        enable_options:[
            { text: '', value: '' },
            { text: 'True', value: true },
            { text: 'False', value: false }
        ]
    };
  },
  components: {
    "v-pagination": VuePagination,
    "vue-simple-spinner": Spinner,
      datePicker,
  },
  methods: {
    onImportNumbers() {
      this.$emit('onImportNumbers', null);
    },
    changeActive: function(active) {
      this.is_active = active;
    },
    getSmsHistory: function(created_on, number_uuid) {
        this.selectedNumber = number_uuid;

      // alert(created_on);
      // alert(number_uuid);
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/sms/list`, {
          headers: {
            "X-Auth-Token": this.token
          },
          params: {
            order_by: created_on,
            to: number_uuid
          }
        })
        .then(res => {
          this.sms_list = res.data.payload.items;
            this.sms_count = res.data.payload.total;
        });

    },
    getTables: function(bool) {
      this.loading = true;
        var order_dir;
        if (this.boolsort == false) {
            order_dir = "desc";
        } else {
            order_dir = "asc";
        }
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/number/list`, {
          params: {
            order_by: this.sortItem,
            order_dir: order_dir,
            is_active: bool,
            per_page: this.perPage
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.loading = false;
          this.numbers = res.data.payload.items;
          this.num = res.data.payload.total;
          this.total = Math.ceil(this.num / Number(this.perPage));
        });
    },
    filterColumnOrder: function() {
        let order_dir = (this.boolsort == false) ? "desc":"asc";
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/number/list`, {
          params: {
            // created_on_gt: this.filterColumnStartTime,
            // created_on_lt: this.filterColumnEndTime,
            //user_name: this.FilterEmail,
             per_page: this.perPage,
            number_uuid: this.FilterNumber,
              enabled: this.FilterEnabled,
              order_by: this.sortItem,
              order_dir: order_dir,

          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.loading = false;
          this.numbers = res.data.payload.items;
          this.num = res.data.payload.total;
          this.total = Math.ceil(this.num / Number(this.perPage));
        });
    },
    getNumbersHistory: function(number_uuid) {
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/user/number/list?number_uuid=%2B${number_uuid
            .split("+")
            .join("")}`,
          {
            params: {
              // is_active:bool,
              per_page: this.perPage
            },
            headers: {
              "X-Auth-Token": this.token
            }
          }
        )
        .then(res => {
          // console.log(res)
          this.items = res.data.payload.items;
        });
    },
    perPagefunc: function() {
      var order_dir;
      if (this.boolsort == false) {
        order_dir = "desc";
      } else {
        order_dir = "asc";
      }
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/number/list`, {
          params: {
              number_uuid: this.FilterNumber,
              enabled: this.FilterEnabled,
            order_by: this.sortItem,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.loading = false;
          this.numbers = res.data.payload.items;
          this.num = res.data.payload.total;
          this.total = Math.ceil(this.num / Number(this.perPage));
          this.count = Number(this.currentPage * this.perPage);
          this.count1 = Number(this.count - this.perPage + 1);
        });
    },
    paginationTables: function() {
      var order_dir;
      if (this.boolsort == false) {
        order_dir = "desc";
      } else {
        order_dir = "asc";
      }
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/number/list`, {
          params: {
              number_uuid: this.FilterNumber,
              enabled: this.FilterEnabled,
            order_by: this.sortItem,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.loading = false;
          this.numbers = res.data.payload.items;
          this.count = Number(this.currentPage * this.perPage);
          this.count1 = Number(this.count - this.perPage + 1);
        });
    },
    SortColumns: function(item) {
      var order_dir;
      if (this.boolsort == false) {
        order_dir = "desc";
      } else {
        order_dir = "asc";
      }
      this.sortItem = item;
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/number/list`, {
          params: {
            order_by: item,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.loading = false;
          this.numbers = res.data.payload.items;
        });
    },
    GetUsers: function() {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/list`, {
          params: {
            per_page: 1000
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.users = res.data.payload.items;
        });
    },
    getNumberSmsWithDate: function(){
        axios
            .get(`${process.env.VUE_APP_API_BASE_URL}/sms/history/list`, {
                headers: {
                    "X-Auth-Token": this.token
                },
                params: {
                    to: this.selectedNumber,
                    created_on_gt:this.smsStartTime,
                    created_on_lt:this.smsEndTime,


                }
            })
            .then(res => {
                this.sms_list = res.data.payload.items;
                this.sms_count = res.data.payload.total;
            });

    }
  },
  mounted() {
    this.getTables();
    this.GetUsers();
  }
};
</script>
<style>
</style>
