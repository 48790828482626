<template>
  <div class="row">
    <div class="col-12">
      <div v-if="buyType" class="card">
        <div class="card-body">
          <div class="container">
            <div class="pt-3 pb-5">
              <div class="form-group row">
                <label for="app-select" class="col-sm-2 col-form-label">{{ $t('global.App name') }}</label>
                <div class="col-xs-12 col-sm-10">
                  <v-select
                    multiple
                    v-model="AppDataForm.app"
                    :options="options"
                    :reduce="(item) => item"
                    label="app_name"
                    id="app-select"
                    class="style-chooser"
                    placeholder="Select One (选择一个)"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="country-select" class="col-sm-2 col-form-label">{{ $t('global.Country') }}</label>
                <div class="col-sm-4">
                  <select class="form-control" v-model="AppDataForm.country" disabled id="country-select">
                    <option v-for="country in countryList" :value="country" :key="country.country_uuid">
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label for="number-count-input" class="col-sm-2 col-form-label">{{ $t('global.Numbers Count') }}</label>
                <div class="col-sm-2">
                  <input
                    type="number"
                    class="form-control text-center"
                    :min="1"
                    v-model.number.trim="AppDataForm.ncount"
                    id="number-count-input"
                  />
                </div>
              </div>
              <div v-if="type === 'ReservicesNumbers'" class="form-group row">
                <label for="number-of-days-input" class="col-sm-2 col-form-label">{{
                  $t('global.Number of days')
                }}</label>
                <div class="col-sm-2">
                  <input
                    type="Number"
                    class="form-control bg-white text-center"
                    v-model="AppDataForm.duration"
                    :min="1"
                    disabled
                    id="number-of-days-input"
                  />
                </div>
              </div>
              <div v-if="type === 'ReservicesNumbers'" class="form-group row no-gutters">
                <label for="number-of-days-input" class="col-sm-2 col-form-label">Type of numbers</label>
                <div
                  style="transform: translateX(3px);"
                  class="col-sm-2 border btn"
                  @click="AppDataForm.use_new = false"
                  :class="{ 'btn-lightgreen': !AppDataForm.use_new }"
                >
                  {{ $t('global.Used') }}
                  <!--                  <span class="m-0 p-0">($ {{ AppDataForm.app.price_old }})</span>-->
                </div>
                <div
                  style="transform: translateX(3px);"
                  class="col-sm-2 border btn"
                  @click="AppDataForm.use_new = true"
                  :class="{ 'btn-lightgreen': AppDataForm.use_new }"
                >
                  {{ $t('global.Unused') }}
                  <!--                  <span class="m-0 p-0">($ {{ AppDataForm.app.price_new }})</span>-->
                </div>
              </div>
              <div v-if="AppDataForm.app.length" class="pl-0 my-4">
                <table class="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">App name</th>
                      <th scope="col">Type of numbers</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in AppDataForm.app" :key="item.uuid">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ item.app_name }}</td>
                      <td>{{ AppDataForm.use_new ? 'Unused' : 'Used' }}</td>
                      <td>{{ AppDataForm.use_new ? item.price_new : item.price_old }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="totalPrice < 0.15" class="alert alert-warning mb-4" role="alert" style="font-size: 16px;">
                Your total amount must be more than 0.15 USD
              </div>
              <hr class="mt-4" />
              <div class="row justify-content-end no-gutters">
                <span style="font-size: 24px; font-weight: 700;">
                  <span style="font-weight: 700;">Total :</span>
                  $ {{ totalPrice }}
                </span>
              </div>
              <hr />
              <div class="row no-gutters justify-content-end">
                <button
                  :disabled="isDisabled"
                  v-if="account.balance >= totalPrice"
                  class="btn btn-danger mr-2"
                  @click.prevent="orderNow(true)"
                >
                  {{ $t('global.Pay with Credit') }}
                </button>
                <button :disabled="totalPrice < 0.15" class="btn btn-info" @click="orderNow(false)">
                  {{ $t('global.Pay Now') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!buyType" class="card text-center">
        <div class="card-body">
          <div class="row justify-content-center" style="font-size: 20px; font-weight: 700;">
            <span class="d-inline-block">{{ $t('global.Payable Amount') }}</span>
            <span class="d-inline-block" style="margin-left: 100px;">{{ totalPrice }} $</span>
          </div>
          <hr />
          <div class="container" style="margin-top: 32px;">
            <div class="row justify-content-center">
              <!--              <div class="col-xs-12 col-sm-6 col-lg-4" style="margin-bottom: 24px">-->
              <!--                <PayPal-->
              <!--                  env="sandbox"-->
              <!--                  :amount="totalPrice.toString()"-->
              <!--                  currency="USD"-->
              <!--                  :client="paypal"-->
              <!--                  :button-style="paypalButton"-->
              <!--                  @payment-authorized="paymentAuthorized"-->
              <!--                  @payment-canceled="paymentCanceled"-->
              <!--                  @payment-completed="paymentCompleted"-->
              <!--                />-->
              <!--              </div>-->
              <div class="col-xs-12 col-sm-6 col-lg-4" style="margin-bottom: 24px;">
                <CoinpaymentsButton
                  :token="token"
                  :amount="totalPrice.toString()"
                  :order="{ ...AppDataForm, type }"
                  v-on:onCreateTransaction="onPayment"
                  v-on:onError="onPaymentError"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import eventBus from '../event_bus.js'
// import PayPal from "vue-paypal-checkout";
import CoinpaymentsButton from 'coinpayments-button'
import vSelect from 'vue-select'

export default {
  props: ['type', 'buyType'],
  components: {
    // PayPal,
    CoinpaymentsButton,
    vSelect
  },
  data: function () {
    return {
      token: VueCookies.get('usertoken'),
      account: {},
      countryList: [],
      AppDataForm: {
        app: [],
        use_new: true,
        payUsingAvailBalance: true,
        country: false,
        duration: 10,
        ncount: 1
      },
      options: [],
      paypal: {
        sandbox: process.env.PAYPAL_SANDBOX_ID,
        production: process.env.PAYPAL_PRODUCTION__ID
      },
      paypalButton: {
        size: 'responsive',
        shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'paypal',
        tagline: false
      },
      userSpecialPrices: {},
      isDisabled: true
    }
  },
  watch: {
    'AppDataForm.app': {
      deep: true,
      handler(val) {
        this.isDisabled = !val.length
      }
    }
  },
  methods: {
    async checkout() {
      this.$refs.checkoutRef.redirectToCheckout()
    },
    orderNow: function (payUsingAvailBalance = false) {
      this.AppDataForm.payUsingAvailBalance = payUsingAvailBalance
      if (!payUsingAvailBalance) {
        this.$emit('fromChild')
        return
      }

      const apps = this.AppDataForm.app.map((item) => {
        return {
          use_new: this.AppDataForm.use_new ? 'totally_not_included_in_any_order' : 'any available',
          app_uuid: item.uuid,
          ncount: this.AppDataForm.ncount
        }
      })

      const _this = this
      let url, params

      switch (this.type) {
        case 'ReservicesNumbers':
          url = `${process.env.VUE_APP_API_BASE_URL}/user/order/create`
          params = {
            country_uuid: _this.AppDataForm.country.country_uuid,
            apps,
            duration: _this.AppDataForm.duration,
            start_date: new Date(),
            duration_type: 'one time'
          }
          break
        case 'PerUsage':
          url = `${process.env.VUE_APP_API_BASE_URL}/user/order/create_per_sms`
          params = {
            start_date: new Date(),
            country_uuid: _this.AppDataForm.country.country_uuid,
            apps
            // filter_words: [
            //   {
            //     keyword: _this.AppDataForm.app.keywords[0],
            //     action: 'include'
            //   }
            // ]
          }
          break
      }

      if (url) {
        axios
          .post(url, params, { headers: { 'X-Auth-Token': _this.token } })
          .then(function (res) {
            if (!_this.AppDataForm.payUsingAvailBalance && res.data && res.data.object_uuid) {
              let url = `${process.env.VUE_APP_API_BASE_URL}/user/payment/create`
              params = {
                order_uuid: res.data.object_uuid,
                amount: _this.totalPrice,
                paid_on: new Date()
              }
              axios
                .post(url, params, { headers: { 'X-Auth-Token': _this.token } })
                .then(function () {
                  eventBus.$emit('__router:to', 'orderhistory')
                })
                .catch(function (error) {
                  alert(error.response.data.error.reason)
                })
            } else {
              eventBus.$emit('__router:to', 'orderhistory')
            }
          })
          .catch(function (error) {
            alert(error.response.data.error.reason)
          })
      }
    },

    /**
     * PayPal events
     */
    // paymentAuthorized(payload) {
    //   console.log('paymentAuthorized: ',payload)
    // },
    // paymentCanceled(event) {
    //   console.log('paymentCanceled: ', event)
    // },
    // paymentCompleted(event) {
    //   console.log('paymentCompleted: ', event);
    // },
    onPaymentError(e) {
      console.log('error: ', JSON.stringify(e))
    },
    /**
     * CoinPayment events
     * @param {Object} tx
     */
    onPayment(tx) {
      console.log('created new transaction', tx)
      const params = {
        width: 800,
        height: 700,
        menubar: 'no',
        toolbar: 'no',
        location: 'yes',
        status: 'yes',
        resizable: 'yes',
        scrollbars: 'yes'
      }
      window.open(
        tx.checkout_url,
        '_blank',
        Object.keys(params)
          .map((k) => `${k}=${params[k]}`)
          .join(',')
      )
    }
  },
  computed: {
    //
    isShowOrderBtn() {
      return (
        ((this.AppDataForm.payUsingAvailBalance && this.account.balance >= this.totalPrice) ||
          !this.AppDataForm.payUsingAvailBalance) &&
        this.totalPrice >= 0
      )
    },
    totalPrice() {
      if (!this.AppDataForm.app.length) {
        return 0
      }
      switch (this.type) {
        case 'ReservicesNumbers':
          return +(
            this.AppDataForm.app.reduce((sum, item) => {
              return this.AppDataForm.use_new ? sum + item.price_new : sum + item.price_old
            }, 0) * this.AppDataForm.ncount
          ).toFixed(2)
        case 'PerUsage':
          return +(
            this.AppDataForm.app.reduce((sum, item) => {
              return sum + item.price_sms_day
            }, 0) * this.AppDataForm.ncount
          ).toFixed(2)
        default:
          return 0
      }
    },
    usedTotalCost() {
      if (!this.AppDataForm.app) {
        return 0
      }
      return parseInt(this.AppDataForm.ncount * this.AppDataForm.app.price_old * 100) / 100
    },
    unusedTotalCost() {
      if (!this.AppDataForm.app) {
        return 0
      }
      return parseInt(this.AppDataForm.ncount * this.AppDataForm.app.price_new * 100) / 100
    }
  },
  created() {
    let _this = this
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/user`, {
        headers: { 'X-Auth-Token': this.token }
      })
      .then((res) => {
        _this.account = res.data.payload.account
      })

    //special prices
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/app_user_price/list`, {
        headers: {
          'X-Auth-Token': this.token
        }
      })
      .then((res) => {
        let special_prices = {}
        Object.keys(res.data.payload.items).forEach(function (k) {
          special_prices[res.data.payload.items[k].app_uuid] = {
            price_old: res.data.payload.items[k].price_old,
            price_new: res.data.payload.items[k].price_new,
            price_sms_day: res.data.payload.items[k].price_sms_day
          }
        })
        _this.userSpecialPrices = special_prices
      })

    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
        params: { per_page: 1000 },
        headers: { 'X-Auth-Token': _this.token }
      })
      .then(async (res) => {
        let Apps = await res.data.payload.items.filter((item) => item.app_name)
        this.options = await Apps.map((item) => {
          if (item.uuid === 'telegram' || item.uuid === 'gmail') {
            return {
              ...item,
              price_new: this.userSpecialPrices[item.uuid]
                ? this.userSpecialPrices[item.uuid].price_new
                : item.price_new,
              price_old: this.userSpecialPrices[item.uuid]
                ? this.userSpecialPrices[item.uuid].price_old
                : item.price_old,
              price_sms_day: this.userSpecialPrices[item.uuid]
                ? this.userSpecialPrices[item.uuid].price_sms_day
                : item.price_sms_day
            }
          }
          return {
            ...item,
            price_old: Math.round(item.price_old * 100) / 100
          }
        })
      })

    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/country/list`, {
        params: { per_page: 250 },
        headers: { 'X-Auth-Token': _this.token }
      })
      .then((res) => {
        _this.countryList = res.data.payload.items

        for (let cnt of res.data.payload.items) {
          if ('USA' === cnt.name.toUpperCase()) {
            _this.AppDataForm.country = cnt
            break
          }
        }
      })
  }
}
</script>
<style scoped>
.bg-white {
  background-color: white;
}

.control[readonly] {
  background-color: white !important;
}

.col {
  color: red;
}

.centered {
  justify-content: center;
}

.label-name {
  float: left;
}

.mrt5 {
  margin-right: 5px !important;
  margin-top: 5px !important;
}

.mt20 {
  margin-top: 20px !important;
}
</style>
