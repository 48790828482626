<template>
  <div class="container margin-top40" style="height: 80vh;">
    <form @submit="ResetPass">
      <div class="logo">
        <span class="db">
          <img src="assets/images/logo-icon.png" alt="logo" />
        </span>
        <h5 class="font-medium m-b-20">Reset Password</h5>
      </div>
      <div>
        <div class="col-12">
          <div class="form-group row">
            <div class="input-group-prepend">
              <span id="basic-addon1" class="input-group-text">
                <i class="ti-user"></i>
              </span>
            </div>
            <input
              type="password"
              :placeholder="$t('register-page.password')"
              name="pass"
              required="required"
              class="form-control form-control-lg"
              v-model="pass"
            />
          </div>
          <div class="form-group row">
            <div class="input-group-prepend">
              <span id="basic-addon2" class="input-group-text">
                <i class="ti-user"></i>
              </span>
            </div>
            <input
              type="password"
              :placeholder="$t('register-page.confirm-password')"
              name="confirm_pass"
              required="required"
              class="form-control form-control-lg"
              v-model="confirm_pass"
            />
          </div>
          <div class="form-group text-center">
            <span style="color: red;" v-show="error !== ''">{{ error }}</span>
            <span style="color: green;" v-show="success !== ''">{{ success }}</span>
            <div class="col-xs-12 p-b-20">
              <button type="submit" class="btn btn-block btn-lg btn-info" :disabled="!isDisabled">
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import router from './router'

export default {
  data() {
    return {
      pass: '',
      confirm_pass: '',
      error: '',
      success: ''
    }
  },
  computed: {
    isDisabled() {
      return this.pass && this.confirm_pass
    }
  },
  methods: {
    ResetPass() {
      if (this.pass !== this.confirm_pass) {
        this.error = "Passwords didn't match"
        return
      } else this.error = ''

      if (this.$route.query.token) {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/auth/reset_password/${this.$route.query.token}`, {
            passwd: this.pass
          })
          .then(() => {
            alert('Password successfully changed')
            router.push({ name: 'login' })
            location.reload()
          })
      }
    }
  }
}
</script>

<style scoped></style>
