const numbers = [
  {
    number: '+19707097070',
    number_uuid: '20a07cae-a128-4d09-ac83-51c28ea281b4'
  },
  {
    number: '+19706968581',
    number_uuid: '1e2059ad-c818-419f-b653-fa85af04ef66'
  },
  {
    number: '+19706968161',
    number_uuid: 'd7dcf047-6627-4754-baeb-0da28f9b8a89'
  },
  {
    number: '+19708497008',
    number_uuid: 'af4a30ad-4ed0-4d07-a7da-8ce7f0113de0'
  },
  {
    number: '+19708497022',
    number_uuid: 'a58b01be-a15a-4636-a82a-1904ee100355'
  },
  {
    number: '+19706968120',
    number_uuid: '5a5d1006-c49f-4293-883f-686b1f858bc2'
  },
  {
    number: '+19707417627',
    number_uuid: '300b5110-8b7a-495a-8d7d-914c9d733975'
  },
  {
    number: '+19706967013',
    number_uuid: 'f192be81-e8b1-4f58-b570-1cc673ae3083'
  },
  {
    number: '+19708497042',
    number_uuid: '1614f268-3c45-4124-89ca-588f2ef686f4'
  },
  {
    number: '+19706968128',
    number_uuid: '3dfaacf8-bdb1-4c45-8a57-f1f32b776e26'
  }
]

export default numbers
