<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <AppPricing v-if="currentType == 'per_number'" :currency="currency"></AppPricing>
        <Payper v-if="currentType == 'per_sms'" :currency="currency"></Payper>
        <div class="well well-sm">
          {{ $t('global.New number means that the numbers has never been used in our platform') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Payper from './Pricing/UserPayperHistory.vue'
import AppPricing from './Pricing/UserApppricingHistory.vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'

export default {
  name: 'UserApppricingHistory',
  components: {
    Payper,
    AppPricing
  },
  data: function () {
    return {
      token: VueCookies.get('usertoken'),
      currentType: 'per_number',
      currency: ''
    }
  },
  created: function () {
    //console.log(this.token)
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/public/usd_convert`, {
        headers: {
          'X-Auth-Token': this.token
        }
      })
      .then((res) => {
        this.currency = parseFloat(res.data.payload.usd2rmb)
      })
  }
}
</script>
<style scoped>
.left-p-20 {
  padding-left: 20px;
}

.price-type-btn.btn-default {
  background-color: #50a2f6;
}
</style>
