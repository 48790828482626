<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!--<div class="col-sm-12 col-md-6">-->
                        <!--<label>Show-->
                            <!--<select v-model='perPage' @change="perPagefunc()" class=" form-control form-control-sm d-inline" style="width: 75px;">-->
                                <!--<option value="10">10</option>-->
                                <!--<option value="25">25</option>-->
                                <!--<option value="50">50</option>-->
                                <!--<option value="100">100</option>-->
                            <!--</select>-->
                            <!--entries</label>-->
                    <!--</div>-->

                    <div  class="modal fade" id="AppEditConf" tabindex="-1" role="dialog" aria-labelledby="AppEditConf" aria-hidden="true">
                        <div class="modal-dialog" role="document" style="max-width: 855px">
                            <div class="modal-content" style="width: 855px;">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="AppEditConf1">Edit </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearForm">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="container">

                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label>App Name</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input v-model="edit_app.app_name" class="form-control" type="text">
                                        </div>
                                    </div>
                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label>UUID</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input v-model="edit_app.uuid" class="form-control" type="text">
                                        </div>
                                    </div>
                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label>Price Old</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input required="required" v-model="edit_app.price_old" class="form-control" min="0" type="number" step="any">
                                        </div>
                                    </div>

                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label >Price New</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input required="required" v-model="edit_app.price_new" class="form-control" min="0" type="number" step="any">
                                        </div>
                                    </div>
                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label >Charge Per SMS Received</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input required="required" v-model="edit_app.price_sms_day" class="form-control" min="0" type="number" step="any">
                                        </div>
                                    </div>

                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label>Keywords</label></b></h4>
                                        </div>
                                        <div class="col-md-6" id="DivForKeywords">
                                            <input class="form-control"  type="text" v-model="edit_app.keywords">
                                        </div>
                                    </div>

                                    <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                        <div class="col-md-4">
                                            <h4><b><label>Days</label></b></h4>
                                        </div>
                                        <div class="col-md-8">
                                            <input v-model="edit_app.minimum_wait_days" class="form-control" type="text">
                                        </div>
                                    </div>

                                    <div v-if="special_price_user_id">
                                        <h3><b><label>Price For user</label></b></h3>
                                        <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                            <div class="col-md-4">
                                                <h4><b><label>Price Old</label></b></h4>
                                            </div>
                                            <div class="col-md-8">
                                                <input required="required" v-model="edit_app_user.price_old" class="form-control" min="0" type="number" step="any">
                                            </div>
                                        </div>
                                        <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                            <div class="col-md-4">
                                                <h4><b><label>Price New</label></b></h4>
                                            </div>
                                            <div class="col-md-8">
                                                <input required="required" v-model="edit_app_user.price_new" class="form-control" min="0" type="number" step="any">
                                            </div>
                                        </div>
                                        <div class="form-row pl-2 pr-2 mt-2 mb-2">
                                            <div class="col-md-4">
                                                <h4><b><label>Charge Per SMS Received</label></b></h4>
                                            </div>
                                            <div class="col-md-8">
                                                <input required="required" v-model="edit_app_user.price_sms_day" class="form-control" min="0" type="number" step="any">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-info" data-dismiss="modal" @click="SaveTable(edit_app.uuid)">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-striped table-bordered" style="font-size: .875rem;">
                            <thead>
                            <tr>
                                <th @click = "boolsort = !boolsort,SortColumns('uuid')" style="cursor: pointer;">UUID <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th @click = "boolsort = !boolsort,SortColumns('app_name')" style="cursor: pointer;">Name <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th @click = "boolsort = !boolsort,SortColumns('price_old')" style="cursor: pointer;">Old Price <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th @click = "boolsort = !boolsort,SortColumns('price_new')" style="cursor: pointer;">New Price <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th @click = "boolsort = !boolsort,SortColumns('price_sms_day')" style="cursor: pointer;">Charge per SMS Received <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th v-if="special_price_user_id">User's Old Price</th>
                                <th v-if="special_price_user_id">User'sNew Price </th>
                                <th v-if="special_price_user_id">User's Charge per SMS Received </th>
                                <th @click = "boolsort = !boolsort,SortColumns('minimum_wait_days')" style="cursor: pointer;">Wait days <i class="fa fa-sort" aria-hidden="true"></i></th>
                                <th>Keywords</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for = "table in Tables" :key =
                                    "table.uuid">
                                <td>{{ table.uuid }}</td>
                                <td>{{ table.app_name }}</td>
                                <td>{{ table.price_old }}</td>
                                <td>{{ table.price_new }}</td>
                                <td>{{ table.price_sms_day }}</td>

                                <td v-if="special_price_user_id">{{ getPrice(table.uuid,'old') }}</td>
                                <td v-if="special_price_user_id">{{ getPrice(table.uuid,'new') }}</td>
                                <td v-if="special_price_user_id">{{ getPrice(table.uuid,'sms') }}</td>
                                <td>{{ table.minimum_wait_days }}</td>
                                <td><span v-for="keyword in table.keywords" :key="keyword.id" style="background: grey;color: white" class="ml-2 p-1">{{ keyword }}</span></td>
                                <td>
                                    <button type="button" class="btn btn-info" data-toggle="modal" data-target="#AppEditConf" :uuid = table.uuid @click="EditTable(table.uuid)">Edit</button>
                                    <button class="btn btn-danger" data-toggle="modal" data-target="#deleteManagement" @click="showDeleteModal(table.uuid)">Delete</button>
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#checkAvailable" @click="showAvailableModal(table.uuid)">Check Available</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--:uuid = table.uuid @click="DeleteTable(table.uuid)"-->
                    <!--<div class="row">-->
                        <!--<div class="col-sm-12 col-md-5">-->
                            <!--<div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">-->
                                <!--Showing {{ count1 }} to {{ count }} of {{ num }} entries-->
                            <!--</div>-->
                        <!--</div>-->
                        <!--<nav class="col-sm-12 col-md-7">-->
                            <!--<ul class="pagination justify-content-end" @click="paginationTables()">-->
                                <!--<v-pagination :total="total" v-model="currentPage"></v-pagination>-->
                            <!--</ul>-->
                        <!--</nav>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteManagement" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Please confirm to delete this APP.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="DeleteTable()">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="checkAvailable" tabindex="-1" role="dialog" aria-labelledby="checkAvailableLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="checkAvailableLabel">Check Available</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_available" message="Loading..."></vue-simple-spinner>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                <thead>
                                <tr>
                                    <th>{{ $t('global.New') }}</th>
                                    <th>{{ $t('global.SMS not received') }}</th>
                                    <th>{{ $t('global.Used') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-if="!Object.keys(available_list).length">
                                    <td style="text-align: center" colspan="3">No Data Found</td>
                                </tr>
                                <tr v-else>
                                    <td>
                                        <a data-toggle="modal" data-target="#availableDetailModal" href="#" @click="showDetailed('totally_not_included_in_any_order')">
                                            {{ available_list.totally_not_included_in_any_order }}
                                        </a>
                                    </td>
                                    <td>
                                        <a data-toggle="modal" data-target="#availableDetailModal" href="#" @click="showDetailed('included_in_order_but_no_sms')">
                                            {{ available_list.included_in_order_but_no_sms }}
                                        </a>
                                    </td>
                                    <td>
                                        <a data-toggle="modal" data-target="#availableDetailModal" href="#" @click="showDetailed('included_in_order_and_has_sms')">
                                            {{ available_list.included_in_order_and_has_sms }}
                                        </a>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('global.Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="availableDetailModal" tabindex="-1" role="dialog" aria-labelledby="availableDetailModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="availableDetailModalLabel">Details</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <vue-simple-spinner v-if="loading_available" message="Loading..."></vue-simple-spinner>

                            <div v-if="TablesAvailable.length" class="table-responsive">
                                <table class="table table-striped table-bordered" style="font-size: .875rem;">
                                    <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Create On</th>
                                        <th>Last Received</th>
                                        <th>Last Order</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for = "table in TablesAvailable" :key =
                                            "table.number_uuid">
                                        <td>{{ table.number_uuid }}</td>
                                        <td>{{ table.created_on }}</td>
                                        <td>{{ table.last_used }}</td>
                                        <td>{{ table.last_order }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row" v-if="TablesAvailable.length">
                                <div class="col-sm-12 col-md-5">
                                    <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                                        Showing {{ count1_available }} to {{ count_available }} of {{ num_available }} entries
                                    </div>
                                </div>
                                <nav class="col-sm-12 col-md-7">
                                    <ul class="pagination justify-content-end" @click="paginationTablesAvailable()">
                                        <v-pagination :total="total_available" v-model="currentPageAvailable"></v-pagination>
                                    </ul>
                                </nav>
                            </div>


                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import VueCookies from "vue-cookies";
import VuePagination from "vue-bs-pagination";
// import datePicker from "vue-bootstrap-datetimepicker";
import Spinner from "vue-simple-spinner";


export default {
  name: "AdminApp",
    props:['specialPriceUser'],
  data: function() {
    return {
      token: VueCookies.get("admintoken"),
        special_price_user_id: this.specialPriceUser ? this.specialPriceUser:null,
        loading_available:false,
      payment_list: [],
      pagesCount: "",
      total: 1,
      currentPage: 1,
      perPage: 10,
      eachSide: 1,
      num: 0,
      count: 10,
      count1: 1,
        total_available: 1,
        currentPageAvailable: 1,
        perPageAvailable:10,
        count_available:10,
        count1_available:1,
        num_available:0,
      boolsort: false,
      sortItem: "",
      filterColumn: "",
      filterColumnStartTime: "",
      filterColumnEndTime: "",
      Tables: [],
        TablesAvailable:[],
      app_name_edit: "",
      keywords_edit: "",
      minimum_wait_days_edit: "",
      price_old_edit: "",
      price_new_edit: "",
      uuid_edit: "",
      edit_app:{},
        edit_app_user:{},
      deletedManId: "",
      users: [],
      filterColumnWait: "",
      filterColumnApp: "",
      filterColumnOld: "",
      filterColumnNew: "",
        special_price_exist:false,
        available_list:{},
        active_app_uuid:null,
        active_type:null,
        user_prices:null,
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,

      }
    };
  },
  components: {
      "vue-simple-spinner": Spinner,
    "v-pagination": VuePagination,
    // datePicker
  },
  methods: {
    clearForm() {
      this.edit_app = {}
    },
    getTables: function() {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
          params: {
            app_gt: this.filterColumnApp,
            old_lt: this.filterColumnOld,
            new_lt: this.filterColumnNew,
            wait_lt: this.filterColumnWait
            // per_page:this.perPage,
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.Tables = res.data.payload.items;
        });

      if(this.special_price_user_id){
          axios
              .get(`${process.env.VUE_APP_API_BASE_URL}/app_user_price/list`, {
                  params: {
                      user_id: this.special_price_user_id
                  },
                  headers: {
                      "X-Auth-Token": this.token
                  }
              })
              .then(res => {
                  let new_obj = {};
                  Object.keys(res.data.payload.items).forEach(function (k) {
                      new_obj[res.data.payload.items[k].app_uuid] = {
                          price_old: res.data.payload.items[k].price_old,
                          price_new: res.data.payload.items[k].price_new,
                          price_sms_day: res.data.payload.items[k].price_sms_day
                      };
                  });
                  this.user_prices = new_obj;
              });
      }
    },
    getPrice(uuid,type){
        if(!this.user_prices[uuid]){
            return '-';
        }

        if(type == 'old'){
            return (this.user_prices[uuid].price_old) ? this.user_prices[uuid].price_old:'-';
        }
        if(type == 'new'){
            return (this.user_prices[uuid].price_new) ? this.user_prices[uuid].price_new:'-';
        }
        if(type == 'sms'){
            return (this.user_prices[uuid].price_sms_day) ? this.user_prices[uuid].price_sms_day:'-';
        }
    },
    SortColumns: function(item) {
      var order_dir;
      if (this.boolsort == false) {
        order_dir = "desc";
      } else {
        order_dir = "asc";
      }
      this.sortItem = item;
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
          params: {
            app_gt: this.filterColumnApp,
            old_lt: this.filterColumnOld,
            new_lt: this.filterColumnNew,
            wait_lt: this.filterColumnWait,
            order_by: item,
            order_dir: order_dir
            // per_page:this.perPage,
            // page:this.currentPage-1
          },
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {
          this.Tables = res.data.payload.items;
        });
    },
    DeleteTable: function() {
      var uuid = this.deletedManId;
      axios
        .delete(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}`, {
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(() => {
          this.getTables();
          this.deletedManId = "";
        });
    },
    showDeleteModal: function(item) {
      this.deletedManId = item;
    },
     showAvailableModal: function(uuid) {
         this.available_list = {};
        this.active_app_uuid = uuid;
        this.loading_available = true;
          axios
              .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/available`, {
                  headers: {
                      "X-Auth-Token": this.token
                  },
              })
              .then(res => {
                  this.available_list = {
                      ...res.data.payload
                  }
                  this.loading_available = false;
              });
    },
      showDetailed: function(type){
        this.loading_available = true;
        this.TablesAvailable = {};
        let uuid = this.active_app_uuid;
        this.active_type = type;
        axios
            .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/${type}`, {
                params:{
                    per_page: this.perPageAvailable,
                    page: this.currentPageAvailable - 1
                },
                headers: {
                    "X-Auth-Token": this.token
                },
            })
            .then(res => {
                this.TablesAvailable = res.data.payload.items;
                this.num_available =  res.data.payload.total;
                this.total_available = Math.ceil(this.num_available / Number(this.perPageAvailable));
                this.loading_available = false;
            });
    },
      paginationTablesAvailable: function() {
          this.loading_available = true;
        let type = this.active_type;
          let uuid = this.active_app_uuid;
          var order_dir;
          if (this.boolsort == false) {
              order_dir = "desc";
          } else {
              order_dir = "asc";
          }
          axios
              .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/${type}`, {
                  params: {
                      order_by: this.sortItem,
                      order_dir: order_dir,
                      per_page: this.perPageAvailable,
                      page: this.currentPageAvailable - 1
                  },
                  headers: {
                      "X-Auth-Token": this.token
                  }
              })
              .then(res => {
                  this.TablesAvailable = res.data.payload.items;
                  this.count1_available = Number((this.currentPageAvailable - 1) * this.perPageAvailable + 1);
                  this.count_available = this.count1 + this.TablesAvailable.length - 1;
                  this.loading_available = false;
              });
      },
    EditTable: function(uuid) {

      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}`, {
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(res => {

           this.edit_app = {
             ...res.data.payload,
             keywords: res.data.payload.keywords.join()
           }


          /*this.uuid_edit = res.data.payload.uuid;
          this.app_name_edit = res.data.payload.app_name;
          this.keywords_edit = res.data.payload.keywords;
          this.price_new_edit = res.data.payload.price_new;
          this.price_old_edit = res.data.payload.price_old;
          this.minimum_wait_days_edit = res.data.payload.minimum_wait_days;*/
        });
        if(this.special_price_user_id){
            let user_id = this.special_price_user_id;
            axios
                .get(`${process.env.VUE_APP_API_BASE_URL}/app/user_price/${uuid}/${user_id}`, {
                    headers: {
                        "X-Auth-Token": this.token
                    }
                })
                .then(res => {
                    this.special_price_exist = true;
                    this.edit_app_user = {
                        ...res.data.payload
                    }
                })
                .catch(() => {
                    this.special_price_exist = false;
                    this.edit_app_user.price_old = '';
                    this.edit_app_user.price_new = '';
                    this.edit_app_user.price_sms_day = '';

                });
        }
    },
    SaveTable: function(uuid_edit) {
      let data = {
        ...this.edit_app,
        keywords:this.edit_app.keywords.indexOf(',')? this.edit_app.keywords.split(","):this.edit_app.keywords
      }
      axios
        .patch(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid_edit}`,data, {
          headers: {
            "X-Auth-Token": this.token
          }
        })
        .then(() => {
            this.getTables();
            this.clearForm();
          }
        ).catch(err => {
          alert(`Error:${err.response.data.error_type}`);
        })

        if(this.special_price_user_id){
            var user_data = this.edit_app_user;
            if(!this.special_price_exist){
                axios
                    .post(
                        `${process.env.VUE_APP_API_BASE_URL}/app/user_price`,
                        {
                            price_old: user_data.price_old,
                            price_new: user_data.price_new,
                            price_sms_day: user_data.price_sms_day,
                            app_uuid: uuid_edit,
                            user_id:this.special_price_user_id
                        },
                        {
                            headers: {
                                "X-Auth-Token": this.token
                            }
                        }
                    )
                    .then(() => {
                        // console.log(res)
                    });
            }else{

                axios
                    .patch(`${process.env.VUE_APP_API_BASE_URL}/app/user_price/${uuid_edit}/${this.special_price_user_id}`,user_data, {
                        headers: {
                            "X-Auth-Token": this.token
                        }
                    })
                    .then(() => {
                            this.getTables();
                            alert("Success");
                        }
                    ).catch(err => {
                    alert(`Error:${err.response.data.error_type}`);
                })
            }

        }
    }
  },
  created() {
    this.getTables();
  }
};
</script>

<style scoped>
</style>
