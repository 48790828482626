<template>
  <div>
    <div class="preloader">
      <div class="lds-ripple">
        <div class="lds-pos"></div>
        <div class="lds-pos"></div>
      </div>
    </div>
    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <div id="main-wrapper">
      <!-- ============================================================== -->
      <!-- Topbar header - style you can find in pages.scss -->
      <!-- ============================================================== -->
      <header class="topbar">
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          <div class="navbar-header">
            <!-- This is for the sidebar toggle which is visible on mobile only -->
            <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="#"
              ><i class="ti-menu ti-close"></i
            ></a>
            <!-- ============================================================== -->
            <!-- Logo -->
            <!-- ============================================================== -->
            <b class="logo-icon" style="vertical-align: center;">
              <a href="/admin">
                <img
                  src="../assets/images/logo_v4.png"
                  style="width: 200px; height: 65px;"
                  alt="homepage"
                  class="dark-logo"
                />
              </a>
            </b>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->

            <!-- ============================================================== -->
            <!-- Toggle which is visible on mobile only -->
            <!-- ============================================================== -->
            <a
              class="topbartoggler d-block d-md-none waves-effect waves-light"
              href="#"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              ><i class="ti-more"></i
            ></a>
          </div>
          <div class="navbar-collapse collapse" id="navbarSupportedContent">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-left mr-auto">
              <li class="nav-item d-none d-md-block">
                <a class="nav-link sidebartoggler waves-effect waves-light" href="#" data-sidebartype="mini-sidebar">
                  <i class="mdi mdi-menu font-24" />
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="index.html#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="d-none d-md-block">Create<i class="fa fa-angle-down"></i></span>
                  <span class="d-block d-md-none"><i class="fa fa-plus" style="line-height: 64px;"></i></span>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" data-toggle="modal" data-target="#ModalCreateUser" style="cursor: pointer;">
                    Create User
                  </a>
                  <a
                    class="dropdown-item"
                    data-toggle="modal"
                    data-target="#ModalCreateNumber"
                    style="cursor: pointer;"
                  >
                    Create Number
                  </a>
                  <a class="dropdown-item" data-toggle="modal" data-target="#ModalCreateApp" style="cursor: pointer;">
                    Create App
                  </a>
                  <a class="dropdown-item" @click="ChangeComponent('import', 1)" style="cursor: pointer;">
                    Auto generated
                  </a>
                  <a class="dropdown-item" @click="ChangeComponent('import', 2)" style="cursor: pointer;">
                    Manual Upload
                  </a>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer;"
                    @click="ChangeComponent('create_vendor_order_task')"
                  >
                    Vendor order task
                  </a>
                </div>
              </li>
              <!-- ============================================================== -->
              <!-- Search -->
              <!-- ============================================================== -->
              <li class="nav-item search-box">
                <a class="nav-link waves-effect waves-dark" href="#"
                  ><i class="ti-search" style="line-height: 64px;"></i
                ></a>
                <form class="app-search position-absolute">
                  <input type="text" class="form-control" placeholder="Search &amp; enter" />
                  <a class="srh-btn"><i class="ti-close"></i></a>
                </form>
              </li>
            </ul>
            <!-- ============================================================== -->
            <!-- Right side toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav float-right">
              <!-- ============================================================== -->
              <!-- create new -->
              <!-- ============================================================== -->
              <!--<li class="nav-item dropdown">-->
              <!--<a class="nav-link dropdown-toggle" href="index.html#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
              <!--<i class="flag-icon flag-icon-us" style="line-height: 64px;"></i>-->
              <!--</a>-->
              <!--<div class="dropdown-menu dropdown-menu-right  animated bounceInDown" aria-labelledby="navbarDropdown2">-->
              <!--<a class="dropdown-item" href="index.html#"><i class="flag-icon flag-icon-us"></i> English</a>-->
              <!--<a class="dropdown-item" href="index.html#"><i class="flag-icon flag-icon-fr"></i> French</a>-->
              <!--<a class="dropdown-item" href="index.html#"><i class="flag-icon flag-icon-es"></i> Spanish</a>-->
              <!--<a class="dropdown-item" href="index.html#"><i class="flag-icon flag-icon-de"></i> German</a>-->
              <!--</div>-->
              <!--</li>-->
              <!-- ============================================================== -->
              <!-- Comment -->
              <!-- ============================================================== -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle waves-effect waves-dark"
                  href="index.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="mdi mdi-bell font-24" />
                </a>
                <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown w-25">
                  <span class="with-arrow"><span class="bg-primary"></span></span>
                  <ul class="list-style-none">
                    <li>
                      <div class="drop-title bg-primary text-white">
                        <h4 class="m-b-0 m-t-5">{{ total_not }} New</h4>
                        <span class="font-light">Notifications</span>
                      </div>
                    </li>
                    <li>
                      <div class="message-center notifications">
                        <!-- Message -->
                        <a
                          href="#"
                          v-for="item in notifications"
                          :key="item.uuid"
                          @click="item_not = item"
                          data-toggle="modal"
                          data-target="#NotConf"
                          class="message-item"
                        >
                          <span class="btn btn-danger btn-circle"><i class="fa fa-link"></i></span>
                          <div class="mail-contnet">
                            <h5 class="message-title">{{ item.subject }}</h5>
                            <span class="mail-desc">{{ item.content }}</span>
                            <span class="time">{{ item.sent_on }}</span>
                          </div>
                        </a>
                      </div>
                    </li>
                    <li>
                      <a class="nav-link text-center m-b-5" href="#">
                        <strong>Check all notifications</strong> <i class="fa fa-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- ============================================================== -->
              <!-- End Comment -->
              <!-- ============================================================== -->

              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="index.html"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><img
                    src="../../public/assets/admin/assets/images/users/1.jpg"
                    alt="user"
                    class="rounded-circle"
                    width="31"
                    style="margin-top: 15px;"
                /></a>

                <div class="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                  <span class="with-arrow"><span class="bg-primary"></span></span>
                  <div class="d-flex no-block align-items-center p-15 bg-primary text-white mb-2">
                    <div class="ml-3 mt-2">
                      <p class="m-b-0">{{ admin_email }}</p>
                    </div>
                  </div>
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#MailConf">
                    <i class="fa fa-envelope mr-2" />
                    Mail Configuration
                  </a>
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#ModalChangePassword">
                    <i class="fa fa-lock mr-2" />
                    Change password
                  </a>
                  <a class="dropdown-item" href="#" @click="LogOut()">
                    <i class="fa fa-power-off mr-2" />
                    Logout
                  </a>
                </div>
              </li>
              <!-- ============================================================== -->
              <!-- User profile and search -->
              <!-- ============================================================== -->
            </ul>
          </div>
        </nav>
      </header>
      <!-- ============================================================== -->
      <!-- End Topbar header -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar">
          <!-- Sidebar navigation-->
          <nav class="sidebar-nav">
            <ul id="sidebarnav">
              <!-- User Profile-->
              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" @click="ChangeComponent('orders')" aria-expanded="false"
                  ><i class="mdi mdi-view-dashboard"></i><span class="hide-menu">Orders </span></a
                >
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link two-column waves-effect waves-dark" @click="ChangeComponent('payments')"
                  ><i class="mdi mdi-message"></i><span class="hide-menu">Payments</span></a
                >
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link two-column waves-effect waves-dark" @click="ChangeComponent('message')"
                  ><i class="mdi mdi-message"></i><span class="hide-menu">Messages</span></a
                >
              </li>

              <li class="sidebar-item mega-dropdown">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('numbers')"
                  aria-expanded="false"
                  ><i class="mdi mdi-widgets"></i><span class="hide-menu">Numbers</span></a
                >
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" @click="ChangeComponent('import')" aria-expanded="false"
                  ><i class="mdi mdi-collage"></i><span class="hide-menu">Create Order</span></a
                >
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" @click="ChangeComponent('users')" aria-expanded="false"
                  ><i class="mdi mdi-human"></i><span class="hide-menu">Users</span></a
                >
              </li>

              <li class="sidebar-item">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('import', 1)"
                  aria-expanded="false"
                  ><i class="mdi mdi-format-list-numbers"></i><span class="hide-menu">Import Number</span></a
                >
              </li>

              <li class="sidebar-item">
                <a class="sidebar-link waves-effect waves-dark" @click="ChangeComponent('app')" aria-expanded="false"
                  ><i class="mdi mdi-format-wrap-tight"></i><span class="hide-menu">APP Management</span></a
                >
              </li>
              <li class="sidebar-item">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('order_history')"
                  aria-expanded="false"
                  ><i class="mdi mdi-view-dashboard"></i><span class="hide-menu">Order History</span></a
                >
              </li>
              <!--<li class="sidebar-item"> <a class="sidebar-link waves-effect waves-dark" @click ="ChangeComponent('resellers')" aria-expanded="false"><i class="mdi mdi-human"></i><span class="hide-menu">Resellers</span></a>
              </li>-->

              <!--<li class="sidebar-item"> <a class="sidebar-link waves-effect waves-dark" @click ="ChangeComponent('number_management')" aria-expanded="false"><i class="mdi mdi-format-wrap-tight"></i><span class="hide-menu">Number Management</span></a>
              </li>-->
              <li class="sidebar-item">
                <a
                  class="sidebar-link waves-effect waves-dark"
                  @click="ChangeComponent('auto_vendor_task')"
                  aria-expanded="false"
                  ><i class="mdi mdi-format-wrap-tight"></i><span class="hide-menu">Auto Vendor Order</span></a
                >
              </li>
            </ul>
          </nav>
          <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
      </aside>
      <!-- ============================================================== -->
      <!-- End Left Sidebar - style you can find in sidebar.scss  -->
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Page wrapper  -->
      <!-- ============================================================== -->
      <div class="page-wrapper" style="display: block;">
        <div class="page-breadcrumb">
          <div class="row">
            <div class="col-5 align-self-center">
              <h4 class="page-title" v-if="comp_name === 'orders'">Orders</h4>
              <h4 class="page-title" v-if="comp_name === 'payments'">Payments</h4>
              <h4 class="page-title" v-if="comp_name === 'message'">Messages</h4>
              <h4 class="page-title" v-if="comp_name === 'numbers'">Numbers</h4>
              <h4 class="page-title" v-if="comp_name === 'import'">Import</h4>
              <h4 class="page-title" v-if="comp_name === 'users'">Users</h4>
              <h4 class="page-title" v-if="comp_name === 'order_history'">Order History</h4>
              <h4 class="page-title" v-if="comp_name === 'number_management'">Number Management</h4>
              <h4 class="page-title" v-if="comp_name === 'auto_vendor_task'">Auto Vendor Order</h4>
              <h4 class="page-title" v-if="comp_name === 'create_vendor_order_task'">Create Vendor Order Task</h4>
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <!--<li class="breadcrumb-item"><a href="index.html#">Home</a></li>-->
                    <!--<li class="breadcrumb-item active" aria-current="page">Library</li>-->
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <!-- ============================================================== -->
          <!-- Modals  -->
          <!-- ============================================================== -->
          <div
            class="modal fade"
            id="ModalCreateUser"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Create User</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-row pl-2 pr-2 mt-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="email">Email</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="UserEmail" class="form-control" type="Email" name="email" id="email" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="password">Password</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="UserPass" class="form-control" type="password" name="email" id="password" />
                  </div>
                </div>
                <div class="form-row pl-2 mt-2 mb-3">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="password">Type</label></b>
                    </h4>
                  </div>
                  <div class="col" @click="UserType = !UserType">
                    <input
                      type="checkbox"
                      checked
                      data-toggle="toggle"
                      data-on="Admin"
                      data-off="User"
                      data-onstyle="info"
                      data-offstyle="info"
                      data-width="100"
                    />
                  </div>
                  <div class="col" @click="UserActive = !UserActive">
                    <input
                      type="checkbox"
                      checked
                      data-toggle="toggle"
                      data-on="Active"
                      data-off="Inactive"
                      data-onstyle="info"
                      data-offstyle="info"
                      data-width="100"
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-info" data-dismiss="modal" @click="CreateUser()">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="ModalCreateReseller"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Create Reseller</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-row pl-2 pr-2 mt-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="email">Email</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="ResellerEmail" class="form-control" type="Email" name="email" id="email" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="password">Password</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="ResellerPass" class="form-control" type="password" name="email" id="password" />
                  </div>
                </div>
                <div class="form-row pl-2 mt-2 mb-3">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="password">Type</label></b>
                    </h4>
                  </div>
                  <div class="col" @click="ResellerActive = !ResellerActive">
                    <input
                      type="checkbox"
                      checked
                      data-toggle="toggle"
                      data-on="Active"
                      data-off="Inactive"
                      data-onstyle="info"
                      data-offstyle="info"
                      data-width="100"
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-info" data-dismiss="modal" @click="CreateReseller()">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="ModalCreateNumber"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabelNumber">Create Number</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="email"> Start Number</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="NumberUuid" class="form-control" type="number" name="email" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label for="email">End Number</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="NumberUuid1" class="form-control" type="number" name="email" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-info" data-dismiss="modal" @click="CreateNumber()">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="ModalCreateApp"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content" id="model-content-id">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabelApp">Create App</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label>App Name</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="AppName" class="form-control" type="text" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label>Price Old</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="AppPriceOld" class="form-control" min="0" type="number" step="any" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label>Price New</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="AppPriceNew" class="form-control" min="0" type="number" step="any" />
                  </div>
                </div>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label>Keywords</label></b>
                    </h4>
                  </div>
                  <div class="col-md-6" id="DivForKeywords">
                    <input class="form-control" type="text" v-model="AppKeywords[0]" />
                  </div>
                  <div class="col-md-2">
                    <button class="btn btn-info" @click="count++">+</button>
                  </div>
                </div>
                <admin-input
                  v-for="(count, index) in count"
                  v-model="AppKeywords[index + 1]"
                  @DelInp="DelInp(index)"
                  :id="'a_' + index"
                  :key="index"
                ></admin-input>
                <div class="form-row pl-2 pr-2 mt-2 mb-2">
                  <div class="col-md-4">
                    <h4>
                      <b><label>Days</label></b>
                    </h4>
                  </div>
                  <div class="col-md-8">
                    <input v-model="AppDays" class="form-control" type="text" />
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-info" data-dismiss="modal" @click="CreateApp()">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <ChangePassword @set-new-pass="setNewPass" @on-change-handler="changePass" />

          <!-- ============================================================== -->
          <!-- End Modals  -->
          <!-- ============================================================== -->

          <admin-import
            v-if="comp_name === 'import'"
            :tabType="import_type"
            @onShowOrderHistory="onShowOrderHistory"
            @onShowOrders="onShowOrders"
          />
          <admin-order v-if="comp_name === 'orders'" />
          <admin-payment v-if="comp_name === 'payments'" />
          <admin-message v-if="comp_name === 'message'" />
          <admin-numbers v-if="comp_name === 'numbers'" @onImportNumbers="onImportNumbers" />
          <admin-users v-show="comp_name === 'users'" @onAppManagement="onAppManagement" />
          <admin-app v-show="comp_name === 'app'" :specialPriceUser="app_management_user_id" />
          <admin-order_history v-show="comp_name === 'order_history'" />
          <admin-mail />
          <admin-notification :item_not="item_not" />
          <admin-auto_vendor_task v-if="comp_name === 'auto_vendor_task'" />
          <AdminCreateVendorOrderTask
            v-if="comp_name === 'create_vendor_order_task'"
            @createVendorOrderTask="showVendorOrderList"
          />
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- End Page wrapper  -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->
    <div class="chat-windows"></div>
  </div>
</template>

<script>
import axios from 'axios'
import VueCookies from 'vue-cookies'
import router from '../router.js'

import AdminImport from './AdminImport.vue'
import AdminOrder from './AdminOrder.vue'
import AdminPayment from './AdminPayment.vue'
import AdminNumbers from './AdminNumbers.vue'
import AdminUsers from './AdminUsers.vue'
import Input from './Input.vue'
import AdminMail from './AdminMail.vue'
import AdminNotification from './AdminNotification.vue'
import AdminApp from './AdminApp'
import AdminMessage from './AdminMessages.vue'
import AdminVendorOrderTask from './AdminVendorOrderTask'
import AdminOrderHistory from './AdminOrderHistory'
import AdminCreateVendorOrderTask from './AdminCreateVendorOrderTask'
import ChangePassword from './modals/ChangePassword'

export default {
  data: function () {
    return {
      admin_email: VueCookies.get('adminemail'),
      token: VueCookies.get('admintoken'),
      comp_name: 'orders',
      adminInfo: '',
      UserPass: '',
      UserEmail: '',
      UserActive: true,
      UserType: true,
      newPass: '',
      ResellerPass: '',
      ResellerEmail: '',
      ResellerActive: true,

      NumberUuid: '',
      NumberUuid1: '',

      AppKeywords: [],
      AppDays: '',
      AppPriceOld: '',
      AppPriceNew: '',
      AppName: '',
      count: 0,

      notifications: [],
      total_not: '',
      item_not: '',
      import_type: 2,
      app_management_user_id: null
    }
  },
  components: {
    ChangePassword,
    'admin-import': AdminImport,
    'admin-order': AdminOrder,
    'admin-payment': AdminPayment,
    'admin-message': AdminMessage,
    'admin-numbers': AdminNumbers,
    'admin-users': AdminUsers,
    'admin-input': Input,
    'admin-mail': AdminMail,
    'admin-notification': AdminNotification,
    'admin-app': AdminApp,
    'admin-auto_vendor_task': AdminVendorOrderTask,
    'admin-order_history': AdminOrderHistory,
    AdminCreateVendorOrderTask
  },
  methods: {
    setNewPass(value) {
      this.newPass = value
    },
    onImportNumbers: function () {
      this.ChangeComponent('import', 1)
    },
    onShowOrderHistory: function () {
      this.ChangeComponent('order_history', null)
    },
    onShowOrders: function () {
      this.ChangeComponent('orders', null)
    },
    onAppManagement: function (user_id) {
      this.ChangeComponent('app', null, user_id)
    },
    showVendorOrderList() {
      this.ChangeComponent('auto_vendor_task', null)
    },
    ChangeComponent: function (comp_name, type = null, user_id = null) {
      if (type != null) {
        this.import_type = type
      }
      this.app_management_user_id = user_id
      this.comp_name = comp_name
    },
    CreateUser: function () {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/user/create`,
          {
            passwd: this.UserPass,
            is_active: this.UserActive,
            email: this.UserEmail,
            is_admin: this.UserType
          },
          {
            headers: {
              'X-Auth-Token': this.token
            }
          }
        )
        .then(() => {
          // console.log(res)
        })
    },
    CreateReseller: function () {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/user/create`,
          {
            passwd: this.ResellerPass,
            is_active: this.ResellerActive,
            email: this.ResellerEmail,
            is_reseller: true
          },
          {
            headers: {
              'X-Auth-Token': this.token
            }
          }
        )
        .then(() => {
          // console.log(res)
        })
    },
    CreateNumber: function () {
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/number/create`,
          {
            number_uuid: this.NumberUuid,
            number_uuid1: this.NumberUuid1
          },
          {
            headers: {
              'X-Auth-Token': this.token
            }
          }
        )
        .then(() => {
          // console.log(res)
        })
    },
    CreateApp: function () {
      var temp = []

      for (let i of this.AppKeywords) i && temp.push(i)

      this.AppKeywords = temp

      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/app/create`,
          {
            keywords: this.AppKeywords,
            minimum_wait_days: this.AppDays,
            price_old: this.AppPriceOld,
            price_new: this.AppPriceNew,
            app_name: this.AppName
          },
          {
            headers: {
              'X-Auth-Token': this.token
            }
          }
        )
        .then(
          (res) => {
            if (res.data.success) {
              alert('success')
              this.AppName = ''
              this.AppPriceNew = ''
              this.AppPriceOld = ''
              this.AppDays = ''
              this.AppKeywords = []
              this.comp_name = 'app'
              this.count = 0
              // console.log(this.AppKeywords)
            }
          },
          () => {
            alert('Error!')
          }
        )
    },
    DelInp: function (index) {
      var element = document.getElementById('model-content-id')
      var child = document.getElementById('a_' + index)
      element.removeChild(child)

      this.AppKeywords.splice(index + 1, 1, null)
    },
    GetNotifications: function () {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/notification/list?order_by=sent_on&order_dir=desc`, {
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.notifications = res.data.payload.items
          this.total_not = res.data.payload.total
        })
    },
    LogOut: function () {
      VueCookies.remove('admintoken')
      VueCookies.remove('adminemail')
      router.push({ name: 'home' })
      location.reload()
    },
    getUser() {
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user`, {
          headers: { 'X-Auth-Token': this.token }
        })
        .then((res) => {
          if (res && res.data) this.adminInfo = res.data.payload
        })
    },
    changePass() {
      axios({
        method: 'PATCH',
        url: `${process.env.VUE_APP_API_BASE_URL}/user`,
        data: { passwd: this.newPass },
        headers: { 'X-Auth-Token': this.token }
      }).then((res) => {
        if (res && res.data) this.adminInfo = res.data.payload
      })
    }
  },
  created() {
    this.GetNotifications()
  },
  mounted() {
    this.getUser()
  }
}
</script>
<style></style>
