<template>
  <div class="card">
    <div class="card-body">
      <div class="container my-2">
        <form @submit.prevent="onSubmitHandler">
          <div class="form-group row">
            <label for="vendor-select" class="col-sm-2 col-form-label">Vendor</label>
            <div class="col-sm-4">
              <select id="app-select" class="form-control" v-model="vendor_uuid">
                <option v-for="item in vendorList" :key="item.vendor_id" :value="item.vendor_uuid">{{ item.vendor_name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="user-select" class="col-sm-2 col-form-label">User name</label>
            <div class="col-sm-4">
              <select id="user-select" class="form-control" v-model="user_id">
                <option v-for="item in users" :key="item.email" :value="item.user_id">{{ item.email }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="vendor-select" class="col-sm-2 col-form-label">App name</label>
            <div class="col-sm-4">
              <select id="vendor-select" class="form-control" v-model="app_uuid">
                <option v-for="item in appList" :key="item.uuid" :value="item.uuid">{{ item.app_name }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="number-of-days-input" class="col-sm-2 col-form-label">Duration</label>
            <div class="col-sm-2">
              <input
                type="Number"
                class="form-control bg-white text-center"
                v-model="duration"
                :min="1"
                disabled
                id="number-of-days-input"
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="number-count-input" class="col-sm-2 col-form-label">Numbers Count</label>
            <div class="col-sm-2">
              <input
                type="number"
                class="form-control text-center"
                :min="1"
                v-model.number.trim="numbers_count"
                id="number-count-input"
              />
            </div>
          </div>
          <hr />
          <div class="row no-gutters justify-content-center">
            <button type="submit" class="btn btn-info" style="width:160px">
              Create order
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import vendorApi from './api/vendorApi'
	export default {
		name: 'AdminCreateVendorOrderTask',
    data: () => ({
      vendorList: [],
      appList: [],
      users: [],
      app_uuid: '',
      vendor_uuid: '',
      user_id: 0,
      duration: 10,
      numbers_count: 1
    }),
    async created() {
      this.vendorList = await vendorApi.GET_VENDOR_LIST()
      this.appList = await vendorApi.GET_APP_LIST()
      this.users = await vendorApi.GET_USERS()

    },
    methods: {
      async onSubmitHandler() {
        const payload = {
          app_uuid: this.app_uuid,
          numbers_count: this.numbers_count,
          vendor_uuid: this.vendor_uuid,
          order_duration: this.duration,
          user_id: this.user_id
        }
        const result = await vendorApi.SET_ORDER_TASK(payload)
        if (result.success) this.$emit('createVendorOrderTask')
      }
    }
  }
</script>
