<template>
  <div class="container margin-top40" style="height: 80vh">
    <div class="alert alert-danger margin-bottom40 text-center" role="alert">
      Your email is already registered.
    </div>
    <div class="text-center">
      <button
        class="btn btn-info d-inline-block"
        style="margin:0 16px"
        @click="$router.push({path:'/login'})">
        {{$t('register-page.sign-in')}}
      </button>
      <button
        class="btn btn-info d-inline-block"
        style="margin:0 16px"
        @click="$router.push({path:'/forget_pass'})">
        {{$t('register-page.forget-password')}}
      </button>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'FailureReg'
	}
</script>
