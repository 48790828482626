<template>
  <div class="row no-gutters">
    <div class="col" style="padding-top: 20px;">
      <div class="alert alert-info">
        {{ $t('global.You can buy a number for') }}
        <strong>{{ $t('global.30 days to receive any messages') }}</strong>
      </div>
      <div class="table-responsive">
        <table id="zero_config" class="table table-striped table-bordered" style="font-size: 0.875rem;">
          <thead>
            <tr>
              <th style="cursor: pointer;">
                {{ $t('global.New Number Price') }}
              </th>
              <th style="cursor: pointer;">
                {{ $t('global.Used Number Price') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0.3 USD</td>
              <td>0.15 USD</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="alert alert-info">
        {{ $t('global.You can buy a number') }}
        {{ $t('global.to receive messages from a specific APP only') }}
      </div>
      <div class="table-responsive">
        <table id="zero_config" class="table table-striped table-bordered" style="font-size: 0.875rem;">
          <thead>
            <tr>
              <th style="cursor: pointer;">{{ $t('global.App name') }}</th>
              <th style="cursor: pointer;" colspan="2">
                {{ $t('global.New Number Price') }}
              </th>
              <th style="cursor: pointer;" colspan="2">
                {{ $t('global.Used Number Price') }}
              </th>
              <th>{{ $t('global.Action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in arr" :key="item.uuid">
              <td>{{ item.app_name }}</td>
              <td colspan="2">{{ item.price_new }} USD</td>
              <!--              <td>-->
              <!--                {{-->
              <!--                  parseFloat(parseFloat(item.price_new) * currency).toFixed(2)-->
              <!--                }}-->
              <!--                RMB-->
              <!--              </td>-->
              <td colspan="2">{{ item.price_old }} USD</td>
              <!--              <td>-->
              <!--                {{-->
              <!--                  parseFloat(parseFloat(item.price_old) * currency).toFixed(2)-->
              <!--                }}-->
              <!--                RMB-->
              <!--              </td>-->
              <td>
                <button
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#checkAvailable"
                  @click="showAvailableModal(item.uuid)"
                >
                  {{ $t('global.Check availability') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="modal fade"
          id="checkAvailable"
          tabindex="-1"
          role="dialog"
          aria-labelledby="checkAvailableLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="checkAvailableLabel">
                  {{ $t('global.Check availability') }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <vue-simple-spinner v-if="loading.available" message="Loading..."></vue-simple-spinner>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered" style="font-size: 0.875rem;">
                    <thead>
                      <tr>
                        <th>{{ $t('global.New') }}</th>
                        <th>{{ $t('global.SMS not received') }}</th>
                        <th>{{ $t('global.Used') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!Object.keys(available_list).length">
                        <td style="text-align: center;" colspan="3">
                          No Data Found
                        </td>
                      </tr>
                      <tr v-else>
                        <td>
                          <a
                            data-toggle="modal"
                            data-target="#availableDetailModal"
                            href="#"
                            @click="showDetailed('totally_not_included_in_any_order')"
                          >
                            {{ available_list.totally_not_included_in_any_order }}
                          </a>
                        </td>
                        <td>
                          <a
                            data-toggle="modal"
                            data-target="#availableDetailModal"
                            href="#"
                            @click="showDetailed('included_in_order_but_no_sms')"
                          >
                            {{ available_list.included_in_order_but_no_sms }}
                          </a>
                        </td>
                        <td>
                          <a
                            data-toggle="modal"
                            data-target="#availableDetailModal"
                            href="#"
                            @click="showDetailed('included_in_order_and_has_sms')"
                          >
                            {{ available_list.included_in_order_and_has_sms }}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  {{ $t('global.Cancel') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="availableDetailModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="availableDetailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="availableDetailModalLabel">
                  Details
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <vue-simple-spinner v-if="loading.details" message="Loading..."></vue-simple-spinner>

                <div v-if="TablesAvailable.length" class="table-responsive">
                  <table class="table table-striped table-bordered" style="font-size: 0.875rem;">
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>Create On</th>
                        <th>Last Received</th>
                        <th>Last Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="table in TablesAvailable" :key="table.number_uuid">
                        <td>{{ table.number_uuid }}</td>
                        <td>{{ table.created_on }}</td>
                        <td>{{ table.last_used }}</td>
                        <td>{{ table.last_order }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="TablesAvailable.length">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                      Showing {{ detailsTable.count1_available }} to {{ detailsTable.count_available }} of
                      {{ detailsTable.num_available }} entries
                    </div>
                  </div>
                  <nav class="col-sm-12 col-md-7">
                    <ul class="pagination justify-content-end" @click="paginationTablesAvailable()">
                      <v-pagination
                        :total="detailsTable.total_available"
                        v-model="detailsTable.currentPageAvailable"
                      ></v-pagination>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VuePagination from 'vue-bs-pagination'
export default {
  name: 'UserApppricingHistory',
  props: ['currency'],
  data: function () {
    return {
      token: VueCookies.get('usertoken'),
      arr: [],
      boolsort: false,
      sortItem: '',
      filterColumnApp: '',
      filterColumnOld: '',
      filterColumnNew: '',
      userSpecialPrices: {},
      available_list: {},
      loading_available: false,
      TablesAvailable: {},
      detailsTable: {
        total_available: 1,
        currentPageAvailable: 1,
        perPageAvailable: 10,
        count_available: 10,
        count1_available: 1,
        num_available: 0
      },
      active_app_uuid: null,
      active_type: null,
      loading: {
        available: false,
        details: false
      }
    }
  },
  components: {
    'vue-simple-spinner': Spinner,
    'v-pagination': VuePagination
  },
  methods: {
    compare(a, b) {
      const appA = a.app_name.toUpperCase()
      const appB = b.app_name.toUpperCase()

      let comparison = 0
      if (appA > appB) {
        comparison = 1
      } else if (appA < appB) {
        comparison = -1
      }
      return comparison
    },
    getTables: function () {
      let _this = this
      //check special prices
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app_user_price/list`, {
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          let special_prices = {}
          Object.keys(res.data.payload.items).forEach(function (k) {
            special_prices[res.data.payload.items[k].app_uuid] = {
              price_old: res.data.payload.items[k].price_old,
              price_new: res.data.payload.items[k].price_new,
              price_sms_day: res.data.payload.items[k].price_sms_day
            }
          })
          this.userSpecialPrices = special_prices
        })
        .catch(error => console.log(error?.response?.data?.message))

      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
          params: {
            app_gt: this.filterColumnApp,
            old_lt: this.filterColumnOld,
            new_lt: this.filterColumnNew
            // per_page:this.perPage,
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          let Apps = res.data.payload.items.filter((item) => item.app_name).sort(this.compare)
          for (let i in Apps) {
            let app = Apps[i]
            if (_this.userSpecialPrices[app.uuid]) {
              if (_this.userSpecialPrices[app.uuid].price_new && _this.userSpecialPrices[app.uuid].price_new > 0)
                Apps[i].price_new = _this.userSpecialPrices[app.uuid].price_new
              if (_this.userSpecialPrices[app.uuid].price_old && _this.userSpecialPrices[app.uuid].price_old > 0)
                Apps[i].price_old = _this.userSpecialPrices[app.uuid].price_old
              if (
                _this.userSpecialPrices[app.uuid].price_sms_day &&
                _this.userSpecialPrices[app.uuid].price_sms_day > 0
              )
                Apps[i].price_sms_day = _this.userSpecialPrices[app.uuid].price_sms_day
            }
          }
          this.arr = Apps
        })
        .catch(error => console.log(error?.response?.data?.message))
    },
    SortColumns: function (item) {
      this.sortItem = item
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/list`, {
          params: {
            app_gt: this.filterColumnApp,
            old_lt: this.filterColumnOld,
            new_lt: this.filterColumnNew,
            order_dir: this.boolsort ? 'asc' : 'desc',
            order_by: item
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.arr = res.data.payload.items
        })
    },
    showAvailableModal: function (uuid) {
      this.available_list = {}
      this.active_app_uuid = uuid
      this.loading.available = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/available`, {
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.available_list = {
            ...res.data.payload
          }
          this.loading.available = false
        })
    },
    showDetailed: function (type) {
      this.loading.details = true
      this.TablesAvailable = {}
      let uuid = this.active_app_uuid
      this.active_type = type
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/${type}`, {
          params: {
            per_page: this.detailsTable.perPageAvailable,
            page: this.detailsTable.currentPageAvailable - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.TablesAvailable = res.data.payload.items
          this.detailsTable.num_available = res.data.payload.total
          this.detailsTable.total_available = Math.ceil(
            this.detailsTable.num_available / Number(this.detailsTable.perPageAvailable)
          )
          this.loading.details = false
        })
    },
    paginationTablesAvailable: function () {
      this.loading.details = true
      let type = this.active_type
      let uuid = this.active_app_uuid
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/app/${uuid}/${type}`, {
          params: {
            order_by: this.sortItem,
            order_dir: this.boolsort ? 'asc' : 'desc',
            per_page: this.detailsTable.perPageAvailable,
            page: this.detailsTable.currentPageAvailable - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.TablesAvailable = res.data.payload.items
          this.detailsTable.count1_available = Number(
            (this.detailsTable.currentPageAvailable - 1) * this.detailsTable.perPageAvailable + 1
          )
          this.detailsTable.count_available = this.count1 + this.TablesAvailable.length - 1
          this.loading.details = false
        })
    }
  },
  created() {
    this.getTables()
  }
}
</script>

<style scoped></style>
