<template>
  <div>
    <div class="row">
      <div v-show="type == 0" class="col-12">
        <div class="card">
          <div class="card-body">
            <vue-simple-spinner v-if="loading" message="Loading..."></vue-simple-spinner>
            <div class="col-sm-12 col-md-12">
              <label
                >Show
                <select
                  v-model="perPage"
                  @change="perPagefunc()"
                  class="form-control form-control-sm d-inline"
                  style="width: 75px;"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div
              class="modal fade"
              id="ModalNumbers"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Show Numbers</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <tbody v-for="array in numbersArray" :key="array.number_uuid">
                        <tr>
                          <td>{{ array.number_uuid }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 ml-2 mt-1">
              <div class="form-row">
                <span>{{ $t('global.Date') }}</span>
                <div class="col-md-2">
                  <date-picker
                    v-model="filterColumnDateFrom"
                    :config="options"
                    :placeholder="$t('global.From')"
                  ></date-picker>
                </div>
                <span><b>-</b></span>
                <div class="col-md-2">
                  <date-picker
                    v-model="filterColumnDateTo"
                    :config="options"
                    :placeholder="$t('global.To')"
                  ></date-picker>
                </div>
                <span>{{ $t('global.Active Between') }}</span>
                <div class="col-md-2">
                  <date-picker v-model="filterColumnFrom" :config="options" :placeholder="$t('global.From')" />
                </div>
                <span><b>-</b></span>
                <div class="col-md-2">
                  <date-picker v-model="filterColumnTo" :config="options" :placeholder="$t('global.To')"></date-picker>
                </div>
              </div>
            </div>
            <div class="mb-3 ml-2 mt-1">
              <div class="form-row">
                <span>{{ $t('global.Order #') }}</span>
                <div class="col-md-2">
                  <input class="form-control" v-model="searchOrder" />
                </div>
                <div class="col-md-2">
                  <button class="btn btn-info" @click="filterColumnOrder()">
                    {{ $t('global.Search') }} <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered" style="font-size: 0.875rem;">
                <thead>
                  <tr>
                    <th>{{ $t('global.Unit Price') }}</th>
                    <th>{{ $t('global.Total Cost') }}</th>
                    <!--<th @click = "boolsort = !boolsort,SortColumns('app_name')" style="cursor: pointer;">APP Name <i class="fa fa-sort" aria-hidden="true"></i></th>-->
                    <th>{{ $t('global.APP Name') }}</th>
                    <th>{{ $t('global.APP UUID') }}</th>
                    <th @click=";(boolsort = !boolsort), SortColumns('created_on')" style="cursor: pointer;">
                      {{ $t('global.Date') }} <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <th @click=";(boolsort = !boolsort), SortColumns('uuid')" style="cursor: pointer;">
                      {{ $t('global.Order #') }} <i class="fa fa-sort" aria-hidden="true"></i>
                    </th>
                    <!--<th @click = "boolsort = !boolsort,SortColumns('duration_type')" style="cursor: pointer;">Type <i class="fa fa-sort" aria-hidden="true"></i></th>-->
                    <th>{{ $t('global.Numbers Count') }}</th>
                    <!--<th @click = "boolsort = !boolsort,SortColumns('payment_typ')" style="cursor: pointer;">Pay With</th>-->
                    <th>{{ $t('global.Start Date') }}</th>
                    <th>{{ $t('global.End Date') }}</th>
                    <th>{{ $t('global.Action') }}</th>
                    <!-- <th>Unit Price</th> -->
                    <!--<th>Active/Inactive</th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in order_list" :key="item.uuid">
                    <td>{{ getAppsUnitPrice(item) }}</td>
                    <td>{{ getAppsAmount(item) }}</td>
                    <td>
                      <a href="#" @click="showApps(item.apps), (type = 2)">{{ getAppNames(item) }}</a>
                    </td>
                    <td>
                      <a href="#" @click="showApps(item.apps), (type = 2)">{{ getAppUUIDs(item) }}</a>
                    </td>
                    <td>{{ item.created_on.split('T').join(' ').split('+').shift().split('.').shift() }}</td>
                    <td>{{ item.uuid }}</td>
                    <!--<td>{{ item.duration_type }}</td>-->
                    <td>
                      <a href="#" @click="showNumbers(item.uuid), (type = 1)">{{
                        item.numbers_count || getAppNumbersCount(item)
                      }}</a>
                    </td>
                    <!--<td>{{ item.payment_type }}</td>-->
                    <td>{{ item.start_date.split('T').join(' ').split('+').shift().split('.').shift() }}</td>
                    <td>{{ item.use_till.split('T').join(' ').split('+').shift().split('.').shift() }}</td>
                    <td>
                      <!-- prettier-ignore -->
                      <export-excel
                        class="btn btn-default"
                        :fetch="() => fetchData(item.uuid, item)"
                        :fields="json_fields"
                        type="csv"
                        :name="item.uuid + '-numbers.xls'"
                      >
                        Export
                      </export-excel>
                    </td>
                    <!-- <td></td> -->
                    <!--<td>{{item.enabled}}</td>-->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
                  {{ $t('global.Showing count1 to count of num entries', { count1: count1, count: count, num: num }) }}
                </div>
              </div>
              <nav class="col-sm-12 col-md-7">
                <ul class="pagination justify-content-end" @click="paginationTables()">
                  <v-pagination :total="total" v-model="currentPage"></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div v-show="type == 1" class="container">
        <button @click="type = 0" class="btn btn-danger">{{ $t('global.Back to Orders') }}</button>
        <br /><br />
        <h2>{{ $t('global.Numbers Table') }}</h2>
        <vue-simple-spinner v-if="numbers.loading" message="Loading..."></vue-simple-spinner>
        <div class="col-sm-12 col-md-12">
          <label
            >Show
            <select
              v-model="numbers.perPage"
              @change="perPagefuncNumber()"
              class="form-control form-control-sm d-inline"
              style="width: 75px;"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries</label
          >
        </div>
        <br /><br />
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('global.Number') }}</th>
              </tr>
            </thead>
            <tbody v-for="array in numbersArray" :key="array.number_uuid">
              <tr>
                <td>{{ array.number_uuid }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="zero_config_info" role="status" aria-live="polite">
              {{
                $t('global.Showing count1 to count of num entries', {
                  count1: numbers.count1,
                  count: numbers.count,
                  num: numbers.num
                })
              }}
            </div>
          </div>
          <nav class="col-sm-12 col-md-7">
            <ul class="pagination justify-content-end" @click="paginationTablesNumber()">
              <v-pagination :total="numbers.total" v-model="numbers.currentPage"></v-pagination>
            </ul>
          </nav>
        </div>
      </div>
      <div v-show="type == 2" class="container">
        <button @click="type = 0" class="btn btn-danger">{{ $t('global.Back to Orders') }}</button>
        <br /><br />
        <h2>{{ $t('global.Apps Table') }}</h2>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('global.Name') }}</th>
              <th>{{ $t('global.UUID') }}</th>
              <th>{{ $t('global.Unit Price') }}</th>
            </tr>
          </thead>
          <tbody v-for="array in appsArray" :key="array.app_uuid">
            <tr>
              <td>{{ array.app_name }}</td>
              <td>{{ array.app_uuid }}</td>
              <td>{{ array.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VuePagination from 'vue-bs-pagination'
import datePicker from 'vue-bootstrap-datetimepicker'
// import UserMyNumbers from "./UserMyNumbers.vue";
// import UserOrderHistory from  './UserOrderHistory.vue'
import Spinner from 'vue-simple-spinner'
import excel from 'vue-excel-export'

Vue.use(excel)

export default {
  data: function () {
    return {
      searchOrder: '',
      appsArray: [],
      loading: false,
      comp_name: '',
      token: VueCookies.get('usertoken'),
      order_list: [],
      pagesCount: '',
      total: 1,
      currentPage: 1,
      perPage: 10,
      eachSide: 1,
      num: 0,
      count: 10,
      count1: 1,
      boolsort: false,
      sortItem: 'created_on',
      numbersArray: [],
      type: 0,

      filterColumnDateFrom: '',
      filterColumnDateTo: '',
      filterColumnFrom: '',
      filterColumnTo: '',

      options: {
        format: 'MM/DD/YYYY',
        useCurrent: false
      },
      json_fields: {
        Number: 'number_uuid',
        UUID: 'uuid'
      },
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      numbers: {
        total: 1,
        currentPage: 1,
        perPage: 10,
        num: 0,
        count: 10,
        count1: 1,
        activeNumber: null
      }
    }
  },
  components: {
    'v-pagination': VuePagination,
    datePicker,
    //    "user-my-numbers1": UserMyNumbers,
    // 'user-order-history1' 		: UserOrderHistory,
    'vue-simple-spinner': Spinner
  },
  methods: {
    showApps(apps) {
      this.appsArray = apps
    },
    ChangeComponent: function (comp_name) {
      this.comp_name = comp_name
    },
    getQueries() {
      var page = this.currentPage - 1
      const per_page = '&per_page=' + this.perPage
      // const sort =  '&order_by=' + this.pageOne.orderBy + '&order_dir=' + this.pageOne.orderDir
      return '?page=' + page + per_page // + sort
    },
    getTables: function () {
      this.loading = true
      axios
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/user/order/list${this.getQueries()}&order_by=created_on&order_dir=desc`,
          {
            headers: {
              'X-Auth-Token': this.token
            }
          }
        )
        .then((res) => {
          this.loading = false
          this.order_list = res.data.payload.items
          this.num = res.data.payload.total
          this.total = Math.ceil(this.num / Number(this.perPage))
        })
    },
    showNumbers: function (uuid) {
      this.numbers.activeNumber = uuid
      this.numbers.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/number/list?order_uuid=${uuid}`, {
          params: {
            per_page: this.numbers.perPage,
            page: this.numbers.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.numbers.loading = false
          this.numbersArray = res.data.payload.items
          this.numbers.num = res.data.payload.total
          this.numbers.total = Math.ceil(this.numbers.num / Number(this.numbers.perPage))
        })
    },
    perPagefuncNumber: function () {
      let uuid = this.numbers.activeNumber
      this.numbers.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/number/list?order_uuid=${uuid}`, {
          params: {
            per_page: this.numbers.perPage,
            page: this.numbers.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.numbersArray = res.data.payload.items
          this.numbers.loading = false
          this.numbers.num = res.data.payload.total
          this.numbers.total = Math.ceil(this.numbers.num / Number(this.numbers.perPage))
          this.numbers.count1 = Number((this.numbers.currentPage - 1) * this.numbers.perPage + 1)
          this.numbers.count = this.numbers.count1 + this.numbersArray.length - 1
        })
    },
    paginationTablesNumber: function () {
      this.numbers.loading = true
      let uuid = this.numbers.activeNumber
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/number/list?order_uuid=${uuid}`, {
          params: {
            per_page: this.numbers.perPage,
            page: this.numbers.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.numbers.loading = false
          this.numbersArray = res.data.payload.items
          this.numbers.count1 = Number((this.numbers.currentPage - 1) * this.numbers.perPage + 1)
          this.numbers.count = this.numbers.count1 + this.numbersArray.length - 1
        })
    },
    async fetchData(uuid, item) {
      const res = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/public/getNumberByToken?per_page=${
          item.numbers_count || this.getAppNumbersCount(item)
        }&token=${uuid}`,
        {
          headers: {
            'X-Auth-Token': this.token
          }
        }
      )
      if (!res.data.payload.items.length) {
        alert('no Data to download')
      }
      return res.data.payload.items
    },
    perPagefunc: function () {
      var order_dir
      if (this.boolsort == false) {
        order_dir = 'desc'
      } else {
        order_dir = 'asc'
      }
      this.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/order/list`, {
          params: {
            created_on_gt: this.filterColumnDateFrom,
            created_on_lt: this.filterColumnDateTo,
            use_till_gt: this.filterColumnFrom,
            use_till_lt: this.filterColumnTo,
            order_by: this.sortItem,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.loading = false
          this.order_list = res.data.payload.items
          this.num = res.data.payload.total
          this.total = Math.ceil(this.num / Number(this.perPage))
          this.count1 = Number((this.currentPage - 1) * this.perPage + 1)
          this.count = this.count1 + this.order_list.length - 1
        })
    },
    paginationTables: function () {
      var order_dir
      if (this.boolsort == false) {
        order_dir = 'desc'
      } else {
        order_dir = 'asc'
      }
      this.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/order/list`, {
          params: {
            created_on_gt: this.filterColumnDateFrom,
            created_on_lt: this.filterColumnDateTo,
            use_till_gt: this.filterColumnFrom,
            use_till_lt: this.filterColumnTo,
            order_by: this.sortItem,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.loading = false
          this.order_list = res.data.payload.items
          this.count1 = Number((this.currentPage - 1) * this.perPage + 1)
          this.count = this.count1 + this.order_list.length - 1
        })
    },
    SortColumns: function (item) {
      var order_dir
      if (this.boolsort == false) {
        order_dir = 'desc'
      } else {
        order_dir = 'asc'
      }
      this.sortItem = item
      this.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/order/list`, {
          params: {
            created_on_gt: this.filterColumnDateFrom,
            created_on_lt: this.filterColumnDateTo,
            use_till_gt: this.filterColumnFrom,
            use_till_lt: this.filterColumnTo,
            order_by: item,
            order_dir: order_dir,
            per_page: this.perPage,
            page: this.currentPage - 1
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.loading = false
          this.order_list = res.data.payload.items
        })
    },
    filterColumnOrder: function () {
      this.loading = true
      axios
        .get(`${process.env.VUE_APP_API_BASE_URL}/user/order/list`, {
          params: {
            created_on_gt: this.filterColumnDateFrom,
            created_on_lt: this.filterColumnDateTo,
            use_till_gt: this.filterColumnFrom,
            use_till_lt: this.filterColumnTo,
            per_page: this.perPage,
            uuid: this.searchOrder,
            order_by: 'created_on',
            order_dir: 'desc'
          },
          headers: {
            'X-Auth-Token': this.token
          }
        })
        .then((res) => {
          this.loading = false
          this.order_list = res.data.payload.items
          this.num = res.data.payload.total
          this.total = Math.ceil(this.num / Number(this.perPage))
        })
    },
    getAppNames(item) {
      let names = []
      for (let app of item.apps) {
        names.push(app.app_name)
      }
      return names.join(', ')
    },
    getAppUUIDs(item) {
      let UUIDs = []
      for (let app of item.apps) {
        UUIDs.push(app.app_uuid)
      }
      return UUIDs.join(', ')
    },
    getAppNumbersCount(item) {
      let counts = []
      for (let app of item.apps) {
        counts.push(app.ncount)
      }
      return counts.join(', ')
      // return counts.reduce((sum, item) => {
      //   return sum + item
      // }, 0)
    },
    getAppsUnitPrice(item) {
      let unit_prices = []
      if (item.billing_plan === 'pay per SMS') {
        for (let app of item.apps) {
          unit_prices.push(app.price_sms_day)
        }
        return unit_prices.join(', ')
      }
      for (let app of item.apps) {
        unit_prices.push(app.price)
      }
      return unit_prices.join(', ')
    },
    getAppsAmount(item) {
      let amount = 0
      if (item.billing_plan === 'pay per SMS') {
        for (let app of item.apps) {
          amount += app.ncount * app.price_sms_day
        }

        return parseFloat(amount).toFixed(2)
      }
      for (let app of item.apps) {
        amount += app.ncount * app.price
      }
      return parseFloat(amount).toFixed(2)
    }
  },
  created() {
    this.getTables()
  }
}
</script>
<style></style>
