<template>
  <div id="app">
    <div class="container padding-top40" style="height: 80vh;">
      <form @submit.prevent="RegisterUser">
        <div class="logo margin-bottom20">
          <img src="assets/images/logo-icon.png" alt="logo" class="margin-bottom10" />
          <h5>{{ $t('register-page.register') }}</h5>
        </div>
        <div class="row" v-if="isError">
          <div class="alert alert-danger text-center" role="alert">
            The password confirmation does not match
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <input
                type="text"
                :placeholder="$t('register-page.name')"
                name="name"
                required="required"
                class="form-control form-control-lg"
                v-model="register_user_data.name"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                :placeholder="$t('register-page.email')"
                name="email"
                required="required"
                class="form-control form-control-lg"
                v-model="register_user_data.email"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                :placeholder="$t('register-page.password')"
                name="password"
                required="required"
                class="form-control form-control-lg"
                v-model="register_user_data.password"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                :placeholder="$t('register-page.confirm-password')"
                name="password"
                required="required"
                class="form-control form-control-lg"
                v-model="register_user_data.password1"
              />
            </div>
            <div class="form-group margin-bottom20">
              <div class="col-md-12">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customCheck1"
                    v-model="register_user_data.checked"
                  />
                  <label class="custom-control-label" for="customCheck1" style="margin-left: 10px; color: #1c458b;">
                    {{ $t('register-page.i-agree') }}
                    <a href="#" style="color: #5a2102;">{{ $t('register-page.terms') }}</a>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group text-center">
              <button type="submit" :disabled="!isSubmit" class="btn btn-block btn-lg btn-info margin-bottom10">
                {{ $t('register-page.sign-up') }}
              </button>
              <div class="col-sm-12 text-center" style="color: #1c458b;">
                {{ $t('register-page.already-have') }}
                <a href="/login" class="text-info m-l-5">
                  <b style="color: #5a2102;">{{ $t('register-page.sign-in') }}</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from './router'

export default {
  name: 'Register',
  data: function () {
    return {
      register_user_data: {
        name: '',
        email: '',
        password: '',
        password1: '',
        checked: false
      },
      isError: false
    }
  },
  computed: {
    isSubmit() {
      return (
        this.register_user_data.name &&
        this.register_user_data.email &&
        this.register_user_data.password &&
        this.register_user_data.password1 &&
        this.register_user_data.checked
      )
    }
  },
  methods: {
    RegisterUser() {
      if (this.register_user_data.password !== this.register_user_data.password1) {
        this.isError = true
        return
      }

      const payload = {
        email: this.register_user_data.email,
        passwd: this.register_user_data.password
      }

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/registration/create`, payload).then(
        (response) => {
          if (response.data && response.data.success) {
            this.isError = false
            router.push({ path: '/success' })
            localStorage.setItem('userId', `${response.data.object_id}`)
          }
        },
        (error) => {
          if (error.response && error.response.data.error) {
            const err = error.response.data.error
            if (err.code === 1000) router.push({ path: '/failure' })
          }
        }
      )
    }
  }
}
</script>
<style>
.form-group {
  margin-bottom: 1rem;
}

form {
  padding: 20px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

input {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 2px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

form .logo {
  text-align: center;
}

.btn-info {
  color: #fff;
  background-color: #2962ff;
  border-color: #2962ff;
}

.btn-group-lg > .btn,
.btn-lg {
  margin-top: 10px;
  padding: 1.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 2px;
}
</style>
