<template>
  <div id="receiveSms" class="wrap-container40">
    <form @submit.prevent="receiveSms">
      <div class="alert alert-danger" role="alert" v-if="error">
        {{ error }}
      </div>
      <div class="form-group" style="margin-bottom: 24px;">
        <label for="number_uuid" style="color: white">Number UUID</label>
        <input class="form-control" v-model="uuid" id="number_uuid" style="font-size: 14px;" />
      </div>
      <div class="form-group" style="margin-bottom: 24px;">
        <label for="message" style="color: white">SMS Message</label>
        <textarea id="message" class="form-control" rows="10" v-model="smsMessage" style="font-size: 14px;color:#000" />
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info" :disabled="!isSubmit">Receive SMS</button>
      </div>
    </form>
  </div>

</template>

<script>
  import api from './api'

  export default {
    name: 'ReceiveSMS',
    data: () => ({
      uuid: '',
      smsMessage: [],
      error: ''
    }),
    computed: {
      isSubmit() {
        return this.uuid
      }
    },
    methods: {
      async receiveSms() {
        this.error = this.smsMessage = ''
        const response = await api.RECEIVE_SMS(this.uuid)
        if (!response.success) {
          this.error = 'Number UUID does not exist'
          return
        }
        const messages = response.payload.items.map(item => item.msg + '\n')
        this.smsMessage = messages.join('')
      }
    }
  }
</script>

<style scoped>

</style>
