<template>
  <div id="app">
    <div class="home-wrap-container">
      <div class="header-content">
        <!-- .container -->
        <div class="container">
          <div class="header-text">
            <div class="header-heading">
              <h1>{{ $t('home-page.headline-msg') }}</h1>
            </div>
            <div class="header-button">
              <a
                href="https://www.youtube.com/embed/jhpKbpd7mlo?autoplay=1"
                class="btn-play-video fancybox-media"
                data-animation="animation-bounce-in"
              >
                Play
              </a>
            </div>
          </div>
        </div>
        <!-- .container end -->
      </div>

      <SmsTable />
      <Price />
    </div>
  </div>
</template>

<script>
import SmsTable from './common/SmsTable.vue'
import Price from './Price.vue'
import axios from 'axios'

export default {
  components: {
    SmsTable,
    Price
  },
  created() {
    if (window.location.search) {
      this.token = window.location.search.split('=').pop()
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/registration/confirm/${this.token}`, {}).then((res) => {
        if (res.data.success) {
          axios
            .post(
              `${process.env.VUE_APP_API_BASE_URL}/user_initial_period`,
              {
                period: 10,
                user_id: +localStorage.getItem('userId')
              },
              { headers: { 'X-Auth-Token': this.token } }
            )
            .then((response) => console.log('response: ', response.data))
          alert('Your Email has been verified, Login to portal to buy SMS DIDs and manange your account', res.data)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-wrap-container .wrap-container8040 {
  background: #fff;
}
</style>
